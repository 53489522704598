// Consolidated Edison colors

// Primary
// $ce-brand-blue is not to be used for text.
$ce-brand-blue: rgb(6, 155, 215); // #069bd7

$ce-energy-blue: rgb(0, 120, 207); // #0078cf
$ce-dark-gray: rgb(42, 42, 42); // #2a2a2a // Text
$ce-medium-gray: rgb(99, 99, 99); // #636363
$ce-white: rgb(255, 255, 255); // #fff

// Secondary
$ce-dark-blue: rgb(0, 90, 163); // #005aa3
$ce-black: rgb(0, 0, 0); // #000
$ce-light-gray: rgb(230, 230, 230); // #e6e6e6
$ce-purple: rgb(102, 0, 153); // #660099
$ce-light-blue: rgb(233, 236, 239); // #e9ecef

// Tertiary
$ce-background-gray: rgb(242, 242, 242); // #f2f2f2
$ce-light-blue-gray: rgb(240, 245, 249); // #f0f5f9
$ce-blue-gray: rgb(198, 234, 242); // #c6eaf2
$ce-yellow: rgb(249, 243, 169); // #f9eaa9

// ~ Other ~

$ce-disabled: rgb(151, 151, 151); // #979797 // TODO Design needs to update

// Outlined Buttons: Dark
$ce-nav-item-focus-blue: rgb(6, 155, 215); // #069bd7 // TODO Design needs to update

$ce-link: rgb(5, 129, 193); // #0581c1 // Used in button mousedown border // TODO Design needs to update
$ce-link-disabled: #4cbfe6; // TODO Design needs to update
$ce-link-hover: #c6eaf2; // TODO Design needs to update

$ce-button-focus-border-blue: rgba(0, 120, 207, 0.5); // #0078cf // 50% opacity energy-blue

/*
$colors: (
    "primary": $ce-brand-blue,
    "secondary": $ce-dark-blue,
    "or-primary": $or-brand-orange,
    "or-secondary":  $or-black,
    "valid":$valid-green,
    "alert": $alert-red
) !default;
*/
