@use 'variables/colors/ce';
@use 'variables/colors/or';

// TODO: needs to be organized and updated

.ce-h1,
.ce-headline,
.ce-typography h1 {
    @include sm {
        font: bolder 1.518rem/1.889rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }

    @include md {
        font: bolder 1.518rem/1.889rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }

    @include lg {
        font: bolder 2.222rem/2.889rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }

    @include xl {
        font: bolder 2.888rem/3.556rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }
}
.ce-h2,
.ce-title,
.ce-typography h2 {
    @include sm {
        font: bolder 1.333rem/1.667rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }

    @include md {
        font: bolder 1.333rem/1.667rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }

    @include lg {
        font: bolder 1.777rem/2.444rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }

    @include xl {
        font: bolder 2.222rem/2.889rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
    }
}
.ce-h3,
.ce-subheading-2,
.ce-typography h3 {
    @include sm {
        font: bolder 1.222rem/1.667rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
    margin: 0 0 16px;
      }
    
      @include md {
        font: bolder 1.222rem/1.667rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
    margin: 0 0 16px;
      }
    
      @include lg {
        font: bolder 1.555rem/2.222rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
    margin: 0 0 16px;
      }
    
      @include xl {
        font: bolder 1.777rem/2.444rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
    margin: 0 0 16px;
      }
    
}
.ce-h4,
.ce-subheading-1,
.ce-typography h4 {
    @include sm {
        font: bolder 1.111rem/1.667rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
        &.normal {
          @include sm {
            font: 1.111rem/1.667rem "Lexend", sans-serif;
          }
        }
      }
    
      @include md {
        font: bolder 1.111rem/1.667rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
        &.normal {
          @include md {
            font: 1.111rem/1.667rem "Lexend", sans-serif;
          }
        }
      }
    
      @include lg {
        font: bolder 1.333rem/2rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
        &.normal {
          @include lg {
            font: 1.333rem/2rem "Lexend", sans-serif;
          }
        }
      }
    
      @include xl {
        font: bolder 1.333rem/2rem "Lexend-ExtraBold", sans-serif;
        letter-spacing: normal;
        margin: 0 0 16px;
        &.normal {
          @include xl {
            font: 1.333rem/2rem "Lexend", sans-serif;
          }
        }
      }
}
.ce-h5,
.ce-typography h5 {
    font: 400 calc(14px * 0.83) / 20px "Lexend", "Lexend-Semibold", "OpenSans";
    margin: 0 0 12px;
}
.ce-h6,
.ce-typography h6 {
    font: 400 calc(14px * 0.67) / 20px "Lexend", "Lexend-Semibold", "OpenSans";
    margin: 0 0 12px;
}
.ce-body-strong,
.ce-body-2 {
    font: 500 14px/24px "Lexend", "Lexend-Semibold", "OpenSans";
    letter-spacing: normal;
}
.ce-body,
.ce-body-1,
.ce-typography {
    font: 1rem/1.5rem "OpenSans", sans-serif;
    letter-spacing: normal;
}
.ce-body p,
.ce-body-1 p,
.ce-typography p {
    @include sm {
        font: 1rem/1.5rem "OpenSans", sans-serif;
        &.bold {
          @include sm {
            font: bolder 1rem/1.5rem "OpenSans", sans-serif;
          }
        }
        &.body2 {
          @include sm {
            font: 0.8rem/1.167rem "OpenSans", sans-serif;
          }
        }
      }
    
      @include md {
        font: 1rem/1.5rem "OpenSans", sans-serif;
        &.bold {
          @include md {
            font: bolder 1rem/1.5rem "OpenSans", sans-serif;
          }
        }
        &.body2 {
          @include md {
            font: 0.8rem/1.167rem "OpenSans", sans-serif;
          }
        }
      }
    
      @include lg {
        font: 1rem/1.5rem "OpenSans", sans-serif;
        &.bold {
          @include lg {
            font: bolder 1rem/1.5rem "OpenSans", sans-serif;
          }
        }
        &.body2 {
          @include lg {
            font: 0.8rem/1.167rem "OpenSans", sans-serif;
          }
        }
      }
    
      @include xl {
        font: 1rem/1.556rem "OpenSans", sans-serif;
        &.bold {
          @include xl {
            font: bolder 1rem/1.556rem "OpenSans", sans-serif;
          }
        }
        &.body2 {
          font: 0.8rem/1.167rem "OpenSans", sans-serif;
        }
      }
    margin: 0 0 12px;
}
.ce-small,
.ce-caption {
    font: 0.64rem/1.25rem "OpenSans", sans-serif;
    letter-spacing: normal;
}
.ce-display-4,
.ce-typography .ce-display-4 {
    font: 300 112px/112px "Lexend", "Lexend-Semibold", "OpenSans";
    letter-spacing: -0.05em;
    margin: 0 0 56px;
}
.ce-display-3,
.ce-typography .ce-display-3 {
    font: 400 56px/56px "Lexend", "Lexend-Semibold", "OpenSans";
    letter-spacing: -0.02em;
    margin: 0 0 64px;
}
.ce-display-2,
.ce-typography .ce-display-2 {
    font: 400 45px/48px "Lexend", "Lexend-Semibold", "OpenSans";
    letter-spacing: -0.005em;
    margin: 0 0 64px;
}
.ce-display-1,
.ce-typography .ce-display-1 {
    font: 400 34px/40px "Lexend", "Lexend-Semibold", "OpenSans";
    letter-spacing: normal;
    margin: 0 0 64px;
}

.ce-table {
    font-family: "Lexend", "Lexend-Semibold", "OpenSans";
}
.ce-header-cell {
    font-size: 12px;
    font-weight: 500;
}
.ce-cell,
.ce-footer-cell {
    font-size: 14px;
}
.ce-calendar {
    font-family: "Lexend", "Lexend-Semibold", "OpenSans";
}
.ce-calendar-body {
    font-size: 13px;
}
.ce-calendar-body-label,
.ce-calendar-period-button {
    font-size: 14px;
    font-weight: 500;
}
.ce-calendar-table-header th {
    font-size: 11px;
    font-weight: 400;
}
.ce-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: "OpenSans", "Arial";
    letter-spacing: normal;
}
.ce-form-field-wrapper {
    padding-bottom: 1.34375em;
}
.ce-form-field-prefix .ce-icon,
.ce-form-field-suffix .ce-icon {
    font-size: 150%;
    line-height: 1.125;
}
.ce-form-field-prefix .ce-icon-button,
.ce-form-field-suffix .ce-icon-button {
    height: 1.5em;
    width: 1.5em;
}
.ce-form-field-prefix .ce-icon-button .ce-icon,
.ce-form-field-suffix .ce-icon-button .ce-icon {
    height: 1.125em;
    line-height: 1.125;
}
.ce-form-field-infix {
    padding: 0.5em 0;
    border-top: 0.84375em solid transparent;
    background-color: $ce-light-gray;
}
.ce-form-field-can-float.ce-form-field-should-float .ce-form-field-label,
.ce-form-field-can-float .ce-input-server:focus + .ce-form-field-label-wrapper .ce-form-field-label {
    transform: translateY(-1.34375em) scale(0.75);
    width: 133.3333333333%;
}
.ce-form-field-can-float .ce-input-server[label]:not(:label-shown) + .ce-form-field-label-wrapper .ce-form-field-label {
    transform: translateY(-1.34374em) scale(0.75);
    width: 133.3333433333%;
}
.ce-form-field-label-wrapper {
    top: -0.84375em;
    padding-top: 0.84375em;
}
.ce-form-field-label {
    top: 1.34375em;
}
.ce-form-field-underline {
    bottom: 1.34375em;
}
.ce-form-field-subscript-wrapper {
    font-size: 75%;
    margin-top: 0.6666666667em;
    top: calc(100% - 1.7916666667em);
}
.ce-form-field-appearance-legacy .ce-form-field-wrapper {
    padding-bottom: 1.25em;
}
.ce-form-field-appearance-legacy .ce-form-field-infix {
    padding: 0.4375em 0;
}
.ce-form-field-appearance-legacy.ce-form-field-can-float.ce-form-field-should-float .ce-form-field-label,
.ce-form-field-appearance-legacy.ce-form-field-can-float
    .ce-input-server:focus
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    -ms-transform: translateY(-1.28125em) scale(0.75);
    width: 133.3333333333%;
}
.ce-form-field-appearance-legacy.ce-form-field-can-float
    .ce-form-field-autofill-control:-webkit-autofill
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
    -ms-transform: translateY(-1.28124em) scale(0.75);
    width: 133.3333433333%;
}
.ce-form-field-appearance-legacy.ce-form-field-can-float
    .ce-input-server[label]:not(:label-shown)
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
    -ms-transform: translateY(-1.28123em) scale(0.75);
    width: 133.3333533333%;
}
.ce-form-field-appearance-legacy .ce-form-field-label {
    top: 1.28125em;
}
.ce-form-field-appearance-legacy .ce-form-field-underline {
    bottom: 1.25em;
}
.ce-form-field-appearance-legacy .ce-form-field-subscript-wrapper {
    margin-top: 0.5416666667em;
    top: calc(100% - 1.6666666667em);
}
@media print {
    .ce-form-field-appearance-legacy.ce-form-field-can-float.ce-form-field-should-float .ce-form-field-label,
    .ce-form-field-appearance-legacy.ce-form-field-can-float
        .ce-input-server:focus
        + .ce-form-field-label-wrapper
        .ce-form-field-label {
        transform: translateY(-1.28122em) scale(0.75);
    }
    .ce-form-field-appearance-legacy.ce-form-field-can-float
        .ce-form-field-autofill-control:-webkit-autofill
        + .ce-form-field-label-wrapper
        .ce-form-field-label {
        transform: translateY(-1.28121em) scale(0.75);
    }
    .ce-form-field-appearance-legacy.ce-form-field-can-float
        .ce-input-server[label]:not(:label-shown)
        + .ce-form-field-label-wrapper
        .ce-form-field-label {
        transform: translateY(-1.2812em) scale(0.75);
    }
}
.ce-form-field-appearance-fill .ce-form-field-infix {
    padding: 0.25em 0 0.75em 0;
}
.ce-form-field-appearance-fill .ce-form-field-label {
    top: 1.09375em;
    margin-top: -0.5em;
}
.ce-form-field-appearance-fill.ce-form-field-can-float.ce-form-field-should-float .ce-form-field-label,
.ce-form-field-appearance-fill.ce-form-field-can-float
    .ce-input-server:focus
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-0.59375em) scale(0.75);
    width: 133.3333333333%;
}
.ce-form-field-appearance-fill.ce-form-field-can-float
    .ce-input-server[label]:not(:label-shown)
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-0.59374em) scale(0.75);
    width: 133.3333433333%;
}
.ce-form-field-appearance-outline .ce-form-field-infix {
    padding: 1em 0 1em 0;
}
.ce-form-field-appearance-outline .ce-form-field-label {
    top: 1.84375em;
    margin-top: -0.25em;
}
.ce-form-field-appearance-outline.ce-form-field-can-float.ce-form-field-should-float .ce-form-field-label,
.ce-form-field-appearance-outline.ce-form-field-can-float
    .ce-input-server:focus
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-1.59375em) scale(0.75);
    width: 133.3333333333%;
}
.ce-form-field-appearance-outline.ce-form-field-can-float
    .ce-input-server[label]:not(:label-shown)
    + .ce-form-field-label-wrapper
    .ce-form-field-label {
    transform: translateY(-1.59374em) scale(0.75);
    width: 133.3333433333%;
}

input.ce-input-element {
    margin-top: -0.0625em;
}

.ce-select {
    font-family: "OpenSans-Semibold", "OpenSans", Arial;
}
.ce-select-trigger {
    height: 1.75rem;
}

.ce-option {
    font-family: "OpenSans";
    font-size: 16px;
}
.ce-optgroup-label {
    font: 500 14px/24px "OpenSans";
    letter-spacing: normal;
}

.ce-ripple {
    overflow: hidden;
    position: relative;
}
.ce-ripple:not(:empty) {
    transform: translateZ(0);
}
.ce-ripple.ce-ripple-unbounded {
    overflow: visible;
}
.ce-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);
}
.cdk-high-contrast-active .ce-ripple-element {
    display: none;
}
.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
}
.cdk-overlay-container:empty {
    display: none;
}
.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
}
.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
}
.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6;
    }
}
.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}
.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll;
}
@keyframes cdk-text-field-autofill-start {
    /*!*/
}
@keyframes cdk-text-field-autofill-end {
    /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
    animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms;
}
textarea.cdk-textarea-autosize {
    resize: none;
}
textarea.cdk-textarea-autosize-measuring {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: auto !important;
    overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: 0 !important;
}
.ce-focus-indicator {
    position: relative;
}
.ce-mdc-focus-indicator {
    position: relative;
}
.ce-ripple-element {
    background-color: rgba(0, 0, 0, 0.1);
}
.ce-option {
    color: rgba(0, 0, 0, 0.87);
}
.ce-option:hover:not(.ce-option-disabled),
.ce-option:focus:not(.ce-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
}
.ce-option.ce-selected:not(.ce-option-multiple):not(.ce-option-disabled) {
    background: rgba(0, 0, 0, 0.04);
}
.ce-option.ce-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
}
.ce-option.ce-option-disabled {
    color: rgba(0, 0, 0, 0.38);
}
.ce-primary .ce-option.ce-selected:not(.ce-option-disabled) {
    color: var(--primaryColor);
}
.ce-accent .ce-option.ce-selected:not(.ce-option-disabled) {
    color: #ffd740;
}
.ce-warn .ce-option.ce-selected:not(.ce-option-disabled) {
    color: #f44336;
}
.ce-optgroup-label {
    color: rgba(0, 0, 0, 0.54);
}
.ce-optgroup-disabled .ce-optgroup-label {
    color: rgba(0, 0, 0, 0.38);
}

.ce-autocomplete-panel {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-autocomplete-panel:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ce-autocomplete-panel .ce-option.ce-selected:not(.ce-active):not(:hover) {
    background: #fff;
}
.ce-autocomplete-panel .ce-option.ce-selected:not(.ce-active):not(:hover):not(.ce-option-disabled) {
    color: rgba(0, 0, 0, 0.87);
}
.ce-badge-content {
    color: #fff;
    background: var(--primaryColor)
}
.cdk-high-contrast-active .ce-badge-content {
    outline: solid 1px;
    border-radius: 0;
}
.ce-badge-accent .ce-badge-content {
    background: #ffd740;
    color: rgba(0, 0, 0, 0.87);
}
.ce-badge-warn .ce-badge-content {
    color: #fff;
    background: #f44336;
}
.ce-badge {
    position: relative;
}
.ce-badge-hidden .ce-badge-content {
    display: none;
}
.ce-badge-disabled .ce-badge-content {
    background: #b9b9b9;
    color: rgba(0, 0, 0, 0.38);
}
.ce-badge-content {
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform 200ms ease-in-out;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
}
.ng-animate-disabled .ce-badge-content,
.ce-badge-content._mat-animation-noopable {
    transition: none;
}
.ce-badge-content.ce-badge-active {
    transform: none;
}
.ce-badge-small .ce-badge-content {
    width: 16px;
    height: 16px;
    line-height: 16px;
}
.ce-badge-small.ce-badge-above .ce-badge-content {
    top: -8px;
}
.ce-badge-small.ce-badge-below .ce-badge-content {
    bottom: -8px;
}
.ce-badge-small.ce-badge-before .ce-badge-content {
    left: -16px;
}
[dir="rtl"] .ce-badge-small.ce-badge-before .ce-badge-content {
    left: auto;
    right: -16px;
}
.ce-badge-small.ce-badge-after .ce-badge-content {
    right: -16px;
}
[dir="rtl"] .ce-badge-small.ce-badge-after .ce-badge-content {
    right: auto;
    left: -16px;
}
.ce-badge-small.ce-badge-overlap.ce-badge-before .ce-badge-content {
    left: -8px;
}
[dir="rtl"] .ce-badge-small.ce-badge-overlap.ce-badge-before .ce-badge-content {
    left: auto;
    right: -8px;
}
.ce-badge-small.ce-badge-overlap.ce-badge-after .ce-badge-content {
    right: -8px;
}
[dir="rtl"] .ce-badge-small.ce-badge-overlap.ce-badge-after .ce-badge-content {
    right: auto;
    left: -8px;
}
.ce-badge-medium .ce-badge-content {
    width: 22px;
    height: 22px;
    line-height: 22px;
}
.ce-badge-medium.ce-badge-above .ce-badge-content {
    top: -11px;
}
.ce-badge-medium.ce-badge-below .ce-badge-content {
    bottom: -11px;
}
.ce-badge-medium.ce-badge-before .ce-badge-content {
    left: -22px;
}
[dir="rtl"] .ce-badge-medium.ce-badge-before .ce-badge-content {
    left: auto;
    right: -22px;
}
.ce-badge-medium.ce-badge-after .ce-badge-content {
    right: -22px;
}
[dir="rtl"] .ce-badge-medium.ce-badge-after .ce-badge-content {
    right: auto;
    left: -22px;
}
.ce-badge-medium.ce-badge-overlap.ce-badge-before .ce-badge-content {
    left: -11px;
}
[dir="rtl"] .ce-badge-medium.ce-badge-overlap.ce-badge-before .ce-badge-content {
    left: auto;
    right: -11px;
}
.ce-badge-medium.ce-badge-overlap.ce-badge-after .ce-badge-content {
    right: -11px;
}
[dir="rtl"] .ce-badge-medium.ce-badge-overlap.ce-badge-after .ce-badge-content {
    right: auto;
    left: -11px;
}
.ce-badge-large .ce-badge-content {
    width: 28px;
    height: 28px;
    line-height: 28px;
}
.ce-badge-large.ce-badge-above .ce-badge-content {
    top: -14px;
}
.ce-badge-large.ce-badge-below .ce-badge-content {
    bottom: -14px;
}
.ce-badge-large.ce-badge-before .ce-badge-content {
    left: -28px;
}
[dir="rtl"] .ce-badge-large.ce-badge-before .ce-badge-content {
    left: auto;
    right: -28px;
}
.ce-badge-large.ce-badge-after .ce-badge-content {
    right: -28px;
}
[dir="rtl"] .ce-badge-large.ce-badge-after .ce-badge-content {
    right: auto;
    left: -28px;
}
.ce-badge-large.ce-badge-overlap.ce-badge-before .ce-badge-content {
    left: -14px;
}
[dir="rtl"] .ce-badge-large.ce-badge-overlap.ce-badge-before .ce-badge-content {
    left: auto;
    right: -14px;
}
.ce-badge-large.ce-badge-overlap.ce-badge-after .ce-badge-content {
    right: -14px;
}
[dir="rtl"] .ce-badge-large.ce-badge-overlap.ce-badge-after .ce-badge-content {
    right: auto;
    left: -14px;
}
.ce-bottom-sheet-container {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-button,
.ce-icon-button,
.ce-stroked-button {
    color: inherit;
    background: transparent;
}
.ce-button.ce-primary,
.ce-icon-button.ce-primary,
.ce-stroked-button.ce-primary {
    color: var(--primaryColor);
}
.ce-button.ce-accent,
.ce-icon-button.ce-accent,
.ce-stroked-button.ce-accent {
    color: #ffd740;
}
.ce-button.ce-warn,
.ce-icon-button.ce-warn,
.ce-stroked-button.ce-warn {
    color: #f44336;
}
.ce-button.ce-primary.ce-button-disabled,
.ce-button.ce-accent.ce-button-disabled,
.ce-button.ce-warn.ce-button-disabled,
.ce-button.ce-button-disabled.ce-button-disabled,
.ce-icon-button.ce-primary.ce-button-disabled,
.ce-icon-button.ce-accent.ce-button-disabled,
.ce-icon-button.ce-warn.ce-button-disabled,
.ce-icon-button.ce-button-disabled.ce-button-disabled,
.ce-stroked-button.ce-primary.ce-button-disabled,
.ce-stroked-button.ce-accent.ce-button-disabled,
.ce-stroked-button.ce-warn.ce-button-disabled,
.ce-stroked-button.ce-button-disabled.ce-button-disabled {
    color: rgba(0, 0, 0, 0.26);
}
.ce-button.ce-primary .ce-button-focus-overlay,
.ce-icon-button.ce-primary .ce-button-focus-overlay,
.ce-stroked-button.ce-primary .ce-button-focus-overlay {
    background-color: var(--bgColor);
}
.ce-button.ce-accent .ce-button-focus-overlay,
.ce-icon-button.ce-accent .ce-button-focus-overlay,
.ce-stroked-button.ce-accent .ce-button-focus-overlay {
    background-color: #ffd740;
}
.ce-button.ce-warn .ce-button-focus-overlay,
.ce-icon-button.ce-warn .ce-button-focus-overlay,
.ce-stroked-button.ce-warn .ce-button-focus-overlay {
    background-color: #f44336;
}
.ce-button.ce-button-disabled .ce-button-focus-overlay,
.ce-icon-button.ce-button-disabled .ce-button-focus-overlay,
.ce-stroked-button.ce-button-disabled .ce-button-focus-overlay {
    background-color: transparent;
}
.ce-button .ce-ripple-element,
.ce-icon-button .ce-ripple-element,
.ce-stroked-button .ce-ripple-element {
    opacity: 0.1;
    background-color: currentColor;
}
.ce-button-focus-overlay {
    background: #000;
}
.ce-stroked-button:not(.ce-button-disabled) {
    border-color: rgba(0, 0, 0, 0.12);
}
.ce-flat-button,
.ce-raised-button,
.ce-fab,
.ce-mini-fab {
    color: rgba(0, 0, 0, 0.87);
    background-color: #fff;
}
.ce-flat-button.ce-primary,
.ce-raised-button.ce-primary,
.ce-fab.ce-primary,
.ce-mini-fab.ce-primary {
    color: #fff;
}
.ce-flat-button.ce-accent,
.ce-raised-button.ce-accent,
.ce-fab.ce-accent,
.ce-mini-fab.ce-accent {
    color: rgba(0, 0, 0, 0.87);
}
.ce-flat-button.ce-warn,
.ce-raised-button.ce-warn,
.ce-fab.ce-warn,
.ce-mini-fab.ce-warn {
    color: #fff;
}
.ce-flat-button.ce-primary.ce-button-disabled,
.ce-flat-button.ce-accent.ce-button-disabled,
.ce-flat-button.ce-warn.ce-button-disabled,
.ce-flat-button.ce-button-disabled.ce-button-disabled,
.ce-raised-button.ce-primary.ce-button-disabled,
.ce-raised-button.ce-accent.ce-button-disabled,
.ce-raised-button.ce-warn.ce-button-disabled,
.ce-raised-button.ce-button-disabled.ce-button-disabled,
.ce-fab.ce-primary.ce-button-disabled,
.ce-fab.ce-accent.ce-button-disabled,
.ce-fab.ce-warn.ce-button-disabled,
.ce-fab.ce-button-disabled.ce-button-disabled,
.ce-mini-fab.ce-primary.ce-button-disabled,
.ce-mini-fab.ce-accent.ce-button-disabled,
.ce-mini-fab.ce-warn.ce-button-disabled,
.ce-mini-fab.ce-button-disabled.ce-button-disabled {
    color: rgba(0, 0, 0, 0.26);
}
.ce-flat-button.ce-primary,
.ce-raised-button.ce-primary,
.ce-fab.ce-primary,
.ce-mini-fab.ce-primary {
    background-color: var(--primaryColor);
}
.ce-flat-button.ce-accent,
.ce-raised-button.ce-accent,
.ce-fab.ce-accent,
.ce-mini-fab.ce-accent {
    background-color: #ffd740;
}
.ce-flat-button.ce-warn,
.ce-raised-button.ce-warn,
.ce-fab.ce-warn,
.ce-mini-fab.ce-warn {
    background-color: #f44336;
}
.ce-flat-button.ce-primary.ce-button-disabled,
.ce-flat-button.ce-accent.ce-button-disabled,
.ce-flat-button.ce-warn.ce-button-disabled,
.ce-flat-button.ce-button-disabled.ce-button-disabled,
.ce-raised-button.ce-primary.ce-button-disabled,
.ce-raised-button.ce-accent.ce-button-disabled,
.ce-raised-button.ce-warn.ce-button-disabled,
.ce-raised-button.ce-button-disabled.ce-button-disabled,
.ce-fab.ce-primary.ce-button-disabled,
.ce-fab.ce-accent.ce-button-disabled,
.ce-fab.ce-warn.ce-button-disabled,
.ce-fab.ce-button-disabled.ce-button-disabled,
.ce-mini-fab.ce-primary.ce-button-disabled,
.ce-mini-fab.ce-accent.ce-button-disabled,
.ce-mini-fab.ce-warn.ce-button-disabled,
.ce-mini-fab.ce-button-disabled.ce-button-disabled {
    background-color: rgba(0, 0, 0, 0.12);
}
.ce-flat-button.ce-primary .ce-ripple-element,
.ce-raised-button.ce-primary .ce-ripple-element,
.ce-fab.ce-primary .ce-ripple-element,
.ce-mini-fab.ce-primary .ce-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
}
.ce-flat-button.ce-accent .ce-ripple-element,
.ce-raised-button.ce-accent .ce-ripple-element,
.ce-fab.ce-accent .ce-ripple-element,
.ce-mini-fab.ce-accent .ce-ripple-element {
    background-color: rgba(0, 0, 0, 0.1);
}
.ce-flat-button.ce-warn .ce-ripple-element,
.ce-raised-button.ce-warn .ce-ripple-element,
.ce-fab.ce-warn .ce-ripple-element,
.ce-mini-fab.ce-warn .ce-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
}
.ce-stroked-button:not([class*="ce-elevation-z"]),
.ce-flat-button:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ce-raised-button:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ce-raised-button:not(.ce-button-disabled):active:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ce-raised-button.ce-button-disabled:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ce-fab:not([class*="ce-elevation-z"]),
.ce-mini-fab:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ce-fab:not(.ce-button-disabled):active:not([class*="ce-elevation-z"]),
.ce-mini-fab:not(.ce-button-disabled):active:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
        0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.ce-fab.ce-button-disabled:not([class*="ce-elevation-z"]),
.ce-mini-fab.ce-button-disabled:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ce-button-toggle-standalone,
.ce-button-toggle-group {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ce-button-toggle-standalone.ce-button-toggle-appearance-standard,
.ce-button-toggle-group-appearance-standard {
    box-shadow: none;
}
.ce-button-toggle {
    color: rgba(0, 0, 0, 0.38);
}
.ce-button-toggle .ce-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12);
}
.ce-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
}
.ce-button-toggle-appearance-standard .ce-button-toggle-focus-overlay {
    background-color: #000;
}
.ce-button-toggle-group-appearance-standard .ce-button-toggle + .ce-button-toggle {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
}
[dir="rtl"] .ce-button-toggle-group-appearance-standard .ce-button-toggle + .ce-button-toggle {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ce-button-toggle-group-appearance-standard.ce-button-toggle-vertical .ce-button-toggle + .ce-button-toggle {
    border-left: none;
    border-right: none;
    border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.ce-button-toggle-checked {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.54);
}
.ce-button-toggle-checked.ce-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, 0.87);
}
.ce-button-toggle-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #eee;
}
.ce-button-toggle-disabled.ce-button-toggle-appearance-standard {
    background: #fff;
}
.ce-button-toggle-disabled.ce-button-toggle-checked {
    background-color: #bdbdbd;
}
.ce-button-toggle-standalone.ce-button-toggle-appearance-standard,
.ce-button-toggle-group-appearance-standard {
    border: solid 1px rgba(0, 0, 0, 0.12);
}
.ce-button-toggle-appearance-standard .ce-button-toggle-label-content {
    line-height: 48px;
}
.ce-card {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-card:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.ce-card.ce-card-flat:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ce-card-subtitle {
    color: rgba(0, 0, 0, 0.54);
}
.ce-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.54);
}
.ce-checkbox-checkmark {
    fill: #fafafa;
}
.ce-checkbox-checkmark-path {
    stroke: #fafafa !important;
}
.ce-checkbox-mixedmark {
    background-color: #fafafa;
}
.ce-checkbox-indeterminate.ce-primary .ce-checkbox-background,
.ce-checkbox-checked.ce-primary .ce-checkbox-background {
    background-color: var(--primaryColor);
}
.ce-checkbox-indeterminate.ce-accent .ce-checkbox-background,
.ce-checkbox-checked.ce-accent .ce-checkbox-background {
    background-color: #ffd740;
}
.ce-checkbox-indeterminate.ce-warn .ce-checkbox-background,
.ce-checkbox-checked.ce-warn .ce-checkbox-background {
    background-color: #f44336;
}
.ce-checkbox-disabled.ce-checkbox-checked .ce-checkbox-background,
.ce-checkbox-disabled.ce-checkbox-indeterminate .ce-checkbox-background {
    background-color: #b0b0b0;
}
.ce-checkbox-disabled:not(.ce-checkbox-checked) .ce-checkbox-frame {
    border-color: #b0b0b0;
}
.ce-checkbox-disabled .ce-checkbox-label {
    color: rgba(0, 0, 0, 0.54);
}
.ce-checkbox .ce-ripple-element {
    background-color: #000;
}
.ce-checkbox-checked:not(.ce-checkbox-disabled).ce-primary .ce-ripple-element,
.ce-checkbox:active:not(.ce-checkbox-disabled).ce-primary .ce-ripple-element {
    background: var(--primaryColor);
}
.ce-checkbox-checked:not(.ce-checkbox-disabled).ce-accent .ce-ripple-element,
.ce-checkbox:active:not(.ce-checkbox-disabled).ce-accent .ce-ripple-element {
    background: #ffd740;
}
.ce-checkbox-checked:not(.ce-checkbox-disabled).ce-warn .ce-ripple-element,
.ce-checkbox:active:not(.ce-checkbox-disabled).ce-warn .ce-ripple-element {
    background: #f44336;
}
.ce-chip.ce-standard-chip {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
}
.ce-chip.ce-standard-chip .ce-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4;
}
.ce-chip.ce-standard-chip:not(.ce-chip-disabled):active {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.ce-chip.ce-standard-chip:not(.ce-chip-disabled) .ce-chip-remove:hover {
    opacity: 0.54;
}
.ce-chip.ce-standard-chip.ce-chip-disabled {
    opacity: 0.4;
}
.ce-chip.ce-standard-chip::after {
    background: #000;
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-primary {
    background-color: var(--bgColor);
    color: #fff;
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-primary .ce-chip-remove {
    color: #fff;
    opacity: 0.4;
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-primary .ce-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-warn {
    background-color: #f44336;
    color: #fff;
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-warn .ce-chip-remove {
    color: #fff;
    opacity: 0.4;
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-warn .ce-ripple-element {
    background-color: rgba(255, 255, 255, 0.1);
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-accent {
    background-color: #ffd740;
    color: rgba(0, 0, 0, 0.87);
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-accent .ce-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4;
}
.ce-chip.ce-standard-chip.ce-chip-selected.ce-accent .ce-ripple-element {
    background-color: rgba(0, 0, 0, 0.1);
}
.ce-table {
    background: #fff;
}
.ce-table thead,
.ce-table tbody,
.ce-table tfoot,
ce-header-row,
ce-row,
ce-footer-row,
[ce-header-row],
[ce-row],
[ce-footer-row],
.ce-table-sticky {
    background: inherit;
}
ce-row,
ce-header-row,
ce-footer-row,
th.ce-header-cell,
td.ce-cell,
td.ce-footer-cell {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}
.ce-header-cell {
    color: rgba(0, 0, 0, 0.54);
}
.ce-cell,
.ce-footer-cell {
    color: rgba(0, 0, 0, 0.87);
}
.ce-calendar-arrow {
    border-top-color: rgba(0, 0, 0, 0.54);
}
.ce-datepicker-toggle,
.ce-datepicker-content .ce-calendar-next-button,
.ce-datepicker-content .ce-calendar-previous-button {
    color: rgba(0, 0, 0, 0.54);
}
.ce-calendar-table-header {
    color: rgba(0, 0, 0, 0.38);
}
.ce-calendar-table-header-divider::after {
    background: rgba(0, 0, 0, 0.12);
}
.ce-calendar-body-label {
    color: rgba(0, 0, 0, 0.54);
}
.ce-calendar-body-cell-content,
.ce-date-range-input-separator {
    color: rgba(0, 0, 0, 0.87);
    border-color: transparent;
}
.ce-calendar-body-disabled
    > .ce-calendar-body-cell-content:not(.ce-calendar-body-selected):not(.ce-calendar-body-comparison-identical) {
    color: rgba(0, 0, 0, 0.38);
}
.ce-form-field-disabled .ce-date-range-input-separator {
    color: rgba(0, 0, 0, 0.38);
}
.ce-calendar-body-cell:not(.ce-calendar-body-disabled):hover
    > .ce-calendar-body-cell-content:not(.ce-calendar-body-selected):not(.ce-calendar-body-comparison-identical),
.cdk-keyboard-focused
    .ce-calendar-body-active
    > .ce-calendar-body-cell-content:not(.ce-calendar-body-selected):not(.ce-calendar-body-comparison-identical),
.cdk-program-focused
    .ce-calendar-body-active
    > .ce-calendar-body-cell-content:not(.ce-calendar-body-selected):not(.ce-calendar-body-comparison-identical) {
    background-color: rgba(0, 0, 0, 0.04);
}
.ce-calendar-body-in-preview {
    color: rgba(0, 0, 0, 0.24);
}
.ce-calendar-body-today:not(.ce-calendar-body-selected):not(.ce-calendar-body-comparison-identical) {
    border-color: rgba(0, 0, 0, 0.38);
}
.ce-calendar-body-disabled
    > .ce-calendar-body-today:not(.ce-calendar-body-selected):not(.ce-calendar-body-comparison-identical) {
    border-color: rgba(0, 0, 0, 0.18);
}
.ce-calendar-body-in-range::before {
    background: rgba(103, 58, 183, 0.2);
}
.ce-calendar-body-comparison-identical,
.ce-calendar-body-in-comparison-range::before {
    background: rgba(249, 171, 0, 0.2);
}
.ce-calendar-body-comparison-bridge-start::before,
[dir="rtl"] .ce-calendar-body-comparison-bridge-end::before {
    background: linear-gradient(to right, rgba(103, 58, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ce-calendar-body-comparison-bridge-end::before,
[dir="rtl"] .ce-calendar-body-comparison-bridge-start::before {
    background: linear-gradient(to left, rgba(103, 58, 183, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ce-calendar-body-in-range > .ce-calendar-body-comparison-identical,
.ce-calendar-body-in-comparison-range.ce-calendar-body-in-range::after {
    background: #a8dab5;
}
.ce-calendar-body-comparison-identical.ce-calendar-body-selected,
.ce-calendar-body-in-comparison-range > .ce-calendar-body-selected {
    background: #46a35e;
}
.ce-calendar-body-selected {
    background-color: var(--bgColor);
    color: #fff;
}
.ce-calendar-body-disabled > .ce-calendar-body-selected {
    background-color: rgba(103, 58, 183, 0.4);
}
.ce-calendar-body-today.ce-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff;
}
.ce-datepicker-content {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-in-range::before {
    background: rgba(255, 215, 64, 0.2);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-comparison-identical,
.ce-datepicker-content.ce-accent .ce-calendar-body-in-comparison-range::before {
    background: rgba(249, 171, 0, 0.2);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-comparison-bridge-start::before,
.ce-datepicker-content.ce-accent [dir="rtl"] .ce-calendar-body-comparison-bridge-end::before {
    background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-comparison-bridge-end::before,
.ce-datepicker-content.ce-accent [dir="rtl"] .ce-calendar-body-comparison-bridge-start::before {
    background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-in-range > .ce-calendar-body-comparison-identical,
.ce-datepicker-content.ce-accent .ce-calendar-body-in-comparison-range.ce-calendar-body-in-range::after {
    background: #a8dab5;
}
.ce-datepicker-content.ce-accent .ce-calendar-body-comparison-identical.ce-calendar-body-selected,
.ce-datepicker-content.ce-accent .ce-calendar-body-in-comparison-range > .ce-calendar-body-selected {
    background: #46a35e;
}
.ce-datepicker-content.ce-accent .ce-calendar-body-selected {
    background-color: #ffd740;
    color: rgba(0, 0, 0, 0.87);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-disabled > .ce-calendar-body-selected {
    background-color: rgba(255, 215, 64, 0.4);
}
.ce-datepicker-content.ce-accent .ce-calendar-body-today.ce-calendar-body-selected {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.ce-datepicker-content.ce-warn .ce-calendar-body-in-range::before {
    background: rgba(244, 67, 54, 0.2);
}
.ce-datepicker-content.ce-warn .ce-calendar-body-comparison-identical,
.ce-datepicker-content.ce-warn .ce-calendar-body-in-comparison-range::before {
    background: rgba(249, 171, 0, 0.2);
}
.ce-datepicker-content.ce-warn .ce-calendar-body-comparison-bridge-start::before,
.ce-datepicker-content.ce-warn [dir="rtl"] .ce-calendar-body-comparison-bridge-end::before {
    background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ce-datepicker-content.ce-warn .ce-calendar-body-comparison-bridge-end::before,
.ce-datepicker-content.ce-warn [dir="rtl"] .ce-calendar-body-comparison-bridge-start::before {
    background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.ce-datepicker-content.ce-warn .ce-calendar-body-in-range > .ce-calendar-body-comparison-identical,
.ce-datepicker-content.ce-warn .ce-calendar-body-in-comparison-range.ce-calendar-body-in-range::after {
    background: #a8dab5;
}
.ce-datepicker-content.ce-warn .ce-calendar-body-comparison-identical.ce-calendar-body-selected,
.ce-datepicker-content.ce-warn .ce-calendar-body-in-comparison-range > .ce-calendar-body-selected {
    background: #46a35e;
}
.ce-datepicker-content.ce-warn .ce-calendar-body-selected {
    background-color: #f44336;
    color: #fff;
}
.ce-datepicker-content.ce-warn .ce-calendar-body-disabled > .ce-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4);
}
.ce-datepicker-content.ce-warn .ce-calendar-body-today.ce-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff;
}
.ce-datepicker-content-touch {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
        0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.ce-datepicker-toggle-active {
    color: var(--primaryColor);
}
.ce-datepicker-toggle-active.ce-accent {
    color: #ffd740;
}
.ce-datepicker-toggle-active.ce-warn {
    color: #f44336;
}
.ce-date-range-input-inner[disabled] {
    color: rgba(0, 0, 0, 0.38);
}
.ce-dialog-container {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-divider {
    border-top-color: rgba(0, 0, 0, 0.12);
}
.ce-divider-vertical {
    border-right-color: rgba(0, 0, 0, 0.12);
}
.ce-expansion-panel {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-expansion-panel:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.ce-action-row {
    border-top-color: rgba(0, 0, 0, 0.12);
}
.ce-expansion-panel .ce-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.ce-expansion-panel .ce-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.ce-expansion-panel:not(.ce-expanded) .ce-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
    .ce-expansion-panel:not(.ce-expanded):not([aria-disabled="true"]) .ce-expansion-panel-header:hover {
        background: #fff;
    }
}
.ce-expansion-panel-header-title {
    color: rgba(0, 0, 0, 0.87);
}
.ce-expansion-panel-header-description,
.ce-expansion-indicator::after {
    color: rgba(0, 0, 0, 0.54);
}
.ce-expansion-panel-header[aria-disabled="true"] {
    color: rgba(0, 0, 0, 0.26);
}
.ce-expansion-panel-header[aria-disabled="true"] .ce-expansion-panel-header-title,
.ce-expansion-panel-header[aria-disabled="true"] .ce-expansion-panel-header-description {
    color: inherit;
}
.ce-expansion-panel-header {
    height: 48px;
}
.ce-expansion-panel-header.ce-expanded {
    height: 64px;
}
.ce-form-field-label {
    color: $ce-black;
}
.ce-hint {
    color: $ce-black;
}
.ce-form-field.ce-focused .ce-form-field-label {
    color: var(--textColor);
    font-size: 12px;
}
.ce-form-field.ce-focused .ce-form-field-label.ce-accent {
    color: #ffd740;
}
.ce-form-field.ce-focused .ce-form-field-label.ce-warn {
    color: #f44336;
}
.ce-focused .ce-form-field-required-marker {
    color: #ffd740;
}
.ce-form-field-ripple {
    background-color: rgba(0, 0, 0, 0.87);
}
.ce-form-field.ce-focused .ce-form-field-ripple {
    background-color: var(--bgColor);
}
.ce-form-field.ce-focused .ce-form-field-ripple.ce-accent {
    background-color: #ffd740;
}
.ce-form-field.ce-focused .ce-form-field-ripple.ce-warn {
    background-color: #f44336;
}
.ce-form-field-type-ce-native-select.ce-focused:not(.ce-form-field-invalid) .ce-form-field-infix::after {
    color: var(--primaryColor);
}
.ce-form-field-type-ce-native-select.ce-focused:not(.ce-form-field-invalid).ce-accent .ce-form-field-infix::after {
    color: #ffd740;
}
.ce-form-field-type-ce-native-select.ce-focused:not(.ce-form-field-invalid).ce-warn .ce-form-field-infix::after {
    color: #f44336;
}
.ce-form-field.ce-form-field-invalid .ce-form-field-label {
    color: #f44336;
}
.ce-form-field.ce-form-field-invalid .ce-form-field-label.ce-accent,
.ce-form-field.ce-form-field-invalid .ce-form-field-label .ce-form-field-required-marker {
    color: #f44336;
}
.ce-form-field.ce-form-field-invalid .ce-form-field-ripple,
.ce-form-field.ce-form-field-invalid .ce-form-field-ripple.ce-accent {
    background-color: #f44336;
}
.ce-error {
    color: #f44336;
}
.ce-form-field-appearance-legacy .ce-form-field-label {
    color: rgba(0, 0, 0, 0.54);
}
.ce-form-field-appearance-legacy .ce-hint {
    color: rgba(0, 0, 0, 0.54);
}
.ce-form-field-appearance-legacy .ce-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
}
.ce-form-field-appearance-legacy.ce-form-field-disabled .ce-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
}
.ce-form-field-appearance-standard .ce-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
}
.ce-form-field-appearance-standard.ce-form-field-disabled .ce-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
}
.ce-form-field-appearance-fill .ce-form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
}
.ce-form-field-appearance-fill.ce-form-field-disabled .ce-form-field-flex {
    background-color: rgba(0, 0, 0, 0.02);
}
.ce-form-field-appearance-fill .ce-form-field-underline::before {
    background-color: rgba(0, 0, 0, 0.42);
}
.ce-form-field-appearance-fill.ce-form-field-disabled .ce-form-field-label {
    color: rgba(0, 0, 0, 0.38);
}
.ce-form-field-appearance-fill.ce-form-field-disabled .ce-form-field-underline::before {
    background-color: transparent;
}
.ce-form-field-appearance-outline .ce-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
}
.ce-form-field-appearance-outline .ce-form-field-outline-thick {
    color: rgba(0, 0, 0, 0.87);
}
.ce-form-field-appearance-outline.ce-focused .ce-form-field-outline-thick {
    color: var(--primaryColor);
}
.ce-form-field-appearance-outline.ce-focused.ce-accent .ce-form-field-outline-thick {
    color: #ffd740;
}
.ce-form-field-appearance-outline.ce-focused.ce-warn .ce-form-field-outline-thick {
    color: #f44336;
}
.ce-form-field-appearance-outline.ce-form-field-invalid.ce-form-field-invalid .ce-form-field-outline-thick {
    color: #f44336;
}
.ce-form-field-appearance-outline.ce-form-field-disabled .ce-form-field-label {
    color: rgba(0, 0, 0, 0.38);
}
.ce-form-field-appearance-outline.ce-form-field-disabled .ce-form-field-outline {
    color: rgba(0, 0, 0, 0.06);
}
.ce-icon.ce-primary {
    color: var(--primaryColor);
}
.ce-icon.ce-accent {
    color: #ffd740;
}
.ce-icon.ce-warn {
    color: #f44336;
}
.ce-form-field-type-ce-native-select .ce-form-field-infix::after {
    color: rgba(0, 0, 0, 0.54);
}
.ce-input-element:disabled,
.ce-form-field-type-ce-native-select.ce-form-field-disabled .ce-form-field-infix::after {
    color: rgba(0, 0, 0, 0.38);
}
.ce-input-element {
    caret-color: var(--primaryColor);
}
.ce-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42);
}
.ce-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42);
}
.ce-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42);
}
.ce-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42);
}
.ce-form-field.ce-accent .ce-input-element {
    caret-color: #ffd740;
}
.ce-form-field.ce-warn .ce-input-element,
.ce-form-field-invalid .ce-input-element {
    caret-color: #f44336;
}
.ce-form-field-type-ce-native-select.ce-form-field-invalid .ce-form-field-infix::after {
    color: #f44336;
}
.ce-list-base .ce-list-item {
    color: rgba(0, 0, 0, 0.87);
}
.ce-list-base .ce-list-option {
    color: rgba(0, 0, 0, 0.87);
}
.ce-list-base .ce-subheader {
    color: rgba(0, 0, 0, 0.54);
}
.ce-list-item-disabled {
    background-color: #eee;
}
.ce-list-option:hover,
.ce-list-option:focus,
.ce-nav-list .ce-list-item:hover,
.ce-nav-list .ce-list-item:focus,
.ce-action-list .ce-list-item:hover,
.ce-action-list .ce-list-item:focus {
    background: rgba(0, 0, 0, 0.04);
}
.ce-list-single-selected-option,
.ce-list-single-selected-option:hover,
.ce-list-single-selected-option:focus {
    background: rgba(0, 0, 0, 0.12);
}
.ce-menu-panel {
    background: #fff;
}
.ce-menu-panel:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ce-menu-item {
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
}
.ce-menu-item[disabled],
.ce-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38);
}
.ce-menu-item .ce-icon-no-color,
.ce-menu-item-submenu-trigger::after {
    color: rgba(0, 0, 0, 0.54);
}
.ce-menu-item:hover:not([disabled]),
.ce-menu-item.cdk-program-focused:not([disabled]),
.ce-menu-item.cdk-keyboard-focused:not([disabled]),
.ce-menu-item-highlighted:not([disabled]) {
    background: rgba(0, 0, 0, 0.04);
}
.ce-paginator {
    background: #fff;
}
.ce-paginator,
.ce-paginator-page-size .ce-select-trigger {
    color: rgba(0, 0, 0, 0.54);
}
.ce-paginator-decrement,
.ce-paginator-increment {
    border-top: 2px solid rgba(0, 0, 0, 0.54);
    border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.ce-paginator-first,
.ce-paginator-last {
    border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.ce-icon-button[disabled] .ce-paginator-decrement,
.ce-icon-button[disabled] .ce-paginator-increment,
.ce-icon-button[disabled] .ce-paginator-first,
.ce-icon-button[disabled] .ce-paginator-last {
    border-color: rgba(0, 0, 0, 0.38);
}
.ce-paginator-container {
    min-height: 56px;
}
.ce-progress-bar-background {
    fill: #d1c4e9;
}
.ce-progress-bar-buffer {
    background-color: #d1c4e9;
}
.ce-progress-bar-fill::after {
    background-color: var(--bgColor);
}
.ce-progress-bar.ce-accent .ce-progress-bar-background {
    fill: #ffe57f;
}
.ce-progress-bar.ce-accent .ce-progress-bar-buffer {
    background-color: #ffe57f;
}
.ce-progress-bar.ce-accent .ce-progress-bar-fill::after {
    background-color: #ffd740;
}
.ce-progress-bar.ce-warn .ce-progress-bar-background {
    fill: #ffcdd2;
}
.ce-progress-bar.ce-warn .ce-progress-bar-buffer {
    background-color: #ffcdd2;
}
.ce-progress-bar.ce-warn .ce-progress-bar-fill::after {
    background-color: #f44336;
}
.ce-progress-spinner circle,
.ce-spinner circle {
    stroke: var(--primaryColor);
}
.ce-progress-spinner.ce-accent circle,
.ce-spinner.ce-accent circle {
    stroke: #ffd740;
}
.ce-progress-spinner.ce-warn circle,
.ce-spinner.ce-warn circle {
    stroke: #f44336;
}
.ce-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.54);
}
.ce-radio-button.ce-primary.ce-radio-checked .ce-radio-outer-circle {
    border-color: var(--primaryColor);
}
.ce-radio-button.ce-primary .ce-radio-inner-circle,
.ce-radio-button.ce-primary .ce-radio-ripple .ce-ripple-element:not(.ce-radio-persistent-ripple),
.ce-radio-button.ce-primary.ce-radio-checked .ce-radio-persistent-ripple,
.ce-radio-button.ce-primary:active .ce-radio-persistent-ripple {
    background-color: var(--bgColor)
}
.ce-radio-button.ce-accent.ce-radio-checked .ce-radio-outer-circle {
    border-color: #ffd740;
}
.ce-radio-button.ce-accent .ce-radio-inner-circle,
.ce-radio-button.ce-accent .ce-radio-ripple .ce-ripple-element:not(.ce-radio-persistent-ripple),
.ce-radio-button.ce-accent.ce-radio-checked .ce-radio-persistent-ripple,
.ce-radio-button.ce-accent:active .ce-radio-persistent-ripple {
    background-color: #ffd740;
}
.ce-radio-button.ce-warn.ce-radio-checked .ce-radio-outer-circle {
    border-color: #f44336;
}
.ce-radio-button.ce-warn .ce-radio-inner-circle,
.ce-radio-button.ce-warn .ce-radio-ripple .ce-ripple-element:not(.ce-radio-persistent-ripple),
.ce-radio-button.ce-warn.ce-radio-checked .ce-radio-persistent-ripple,
.ce-radio-button.ce-warn:active .ce-radio-persistent-ripple {
    background-color: #f44336;
}
.ce-radio-button.ce-radio-disabled.ce-radio-checked .ce-radio-outer-circle,
.ce-radio-button.ce-radio-disabled .ce-radio-outer-circle {
    border-color: rgba(0, 0, 0, 0.38);
}
.ce-radio-button.ce-radio-disabled .ce-radio-ripple .ce-ripple-element,
.ce-radio-button.ce-radio-disabled .ce-radio-inner-circle {
    background-color: rgba(0, 0, 0, 0.38);
}
.ce-radio-button.ce-radio-disabled .ce-radio-label-content {
    color: rgba(0, 0, 0, 0.38);
}
.ce-radio-button .ce-ripple-element {
    background-color: #000;
}
.ce-select-value {
    color: rgba(0, 0, 0, 0.87);
}
.ce-select-placeholder {
    color: rgba(0, 0, 0, 0.42);
}
.ce-select-disabled .ce-select-value {
    color: rgba(0, 0, 0, 0.38);
}
.ce-select-arrow {
    color: rgba(0, 0, 0, 0.54);
}
.ce-select-panel {
    background: #fff;
}
.ce-select-panel:not([class*="ce-elevation-z"]) {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.ce-select-panel .ce-option.ce-selected:not(.ce-option-multiple) {
    background: var(--primaryColor);
    color: $ce-white;
}
.ce-form-field.ce-focused.ce-primary .ce-select-arrow {
    color: var(--primaryColor);
}
.ce-form-field.ce-focused.ce-accent .ce-select-arrow {
    color: #ffd740;
}
.ce-form-field.ce-focused.ce-warn .ce-select-arrow {
    color: #f44336;
}
.ce-form-field .ce-select.ce-select-invalid .ce-select-arrow {
    color: #f44336;
}
.ce-form-field .ce-select.ce-select-disabled .ce-select-arrow {
    color: rgba(0, 0, 0, 0.38);
}
.ce-drawer-container {
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.87);
}
.ce-drawer {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
}
.ce-drawer.ce-drawer-push {
    background-color: #fff;
}
.ce-drawer:not(.ce-drawer-side) {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.ce-drawer-side {
    border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ce-drawer-side.ce-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none;
}
[dir="rtl"] .ce-drawer-side {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: none;
}
[dir="rtl"] .ce-drawer-side.ce-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.ce-drawer-backdrop.ce-drawer-shown {
    background-color: $ce-black;
}
.ce-slide-toggle.ce-checked .ce-slide-toggle-thumb {
    background-color: #ffd740;
}
.ce-slide-toggle.ce-checked .ce-slide-toggle-bar {
    background-color: rgba(255, 215, 64, 0.54);
}
.ce-slide-toggle.ce-checked .ce-ripple-element {
    background-color: #ffd740;
}
.ce-slide-toggle.ce-primary.ce-checked .ce-slide-toggle-thumb {
    background-color: var(--primaryColor);
}
.ce-slide-toggle.ce-primary.ce-checked .ce-slide-toggle-bar {
    background-color: rgba(103, 58, 183, 0.54);
}
.ce-slide-toggle.ce-primary.ce-checked .ce-ripple-element {
    background-color: var(--bgColor);
}
.ce-slide-toggle.ce-warn.ce-checked .ce-slide-toggle-thumb {
    background-color: #f44336;
}
.ce-slide-toggle.ce-warn.ce-checked .ce-slide-toggle-bar {
    background-color: rgba(244, 67, 54, 0.54);
}
.ce-slide-toggle.ce-warn.ce-checked .ce-ripple-element {
    background-color: #f44336;
}
.ce-slide-toggle:not(.ce-checked) .ce-ripple-element {
    background-color: #000;
}
.ce-slide-toggle-thumb {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
}
.ce-slide-toggle-bar {
    background-color: rgba(0, 0, 0, 0.38);
}
.ce-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
}
.ce-primary .ce-slider-track-fill,
.ce-primary .ce-slider-thumb,
.ce-primary .ce-slider-thumb-label {
    background-color: var(--primaryColor);
}
.ce-primary .ce-slider-thumb-label-text {
    color: #fff;
}
.ce-primary .ce-slider-focus-ring {
    background-color: rgba(103, 58, 183, 0.2);
}
.ce-accent .ce-slider-track-fill,
.ce-accent .ce-slider-thumb,
.ce-accent .ce-slider-thumb-label {
    background-color: #ffd740;
}
.ce-accent .ce-slider-thumb-label-text {
    color: rgba(0, 0, 0, 0.87);
}
.ce-accent .ce-slider-focus-ring {
    background-color: rgba(255, 215, 64, 0.2);
}
.ce-warn .ce-slider-track-fill,
.ce-warn .ce-slider-thumb,
.ce-warn .ce-slider-thumb-label {
    background-color: #f44336;
}
.ce-warn .ce-slider-thumb-label-text {
    color: #fff;
}
.ce-warn .ce-slider-focus-ring {
    background-color: rgba(244, 67, 54, 0.2);
}
.ce-slider:hover .ce-slider-track-background,
.cdk-focused .ce-slider-track-background {
    background-color: rgba(0, 0, 0, 0.38);
}
.ce-slider-disabled .ce-slider-track-background,
.ce-slider-disabled .ce-slider-track-fill,
.ce-slider-disabled .ce-slider-thumb {
    background-color: rgba(0, 0, 0, 0.26);
}
.ce-slider-disabled:hover .ce-slider-track-background {
    background-color: rgba(0, 0, 0, 0.26);
}
.ce-slider-min-value .ce-slider-focus-ring {
    background-color: rgba(0, 0, 0, 0.12);
}
.ce-slider-min-value.ce-slider-thumb-label-showing .ce-slider-thumb,
.ce-slider-min-value.ce-slider-thumb-label-showing .ce-slider-thumb-label {
    background-color: rgba(0, 0, 0, 0.87);
}
.ce-slider-min-value.ce-slider-thumb-label-showing.cdk-focused .ce-slider-thumb,
.ce-slider-min-value.ce-slider-thumb-label-showing.cdk-focused .ce-slider-thumb-label {
    background-color: rgba(0, 0, 0, 0.26);
}
.ce-slider-min-value:not(.ce-slider-thumb-label-showing) .ce-slider-thumb {
    border-color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
}
.ce-slider-min-value:not(.ce-slider-thumb-label-showing):hover .ce-slider-thumb,
.ce-slider-min-value:not(.ce-slider-thumb-label-showing).cdk-focused .ce-slider-thumb {
    border-color: rgba(0, 0, 0, 0.38);
}
.ce-slider-min-value:not(.ce-slider-thumb-label-showing):hover.ce-slider-disabled .ce-slider-thumb,
.ce-slider-min-value:not(.ce-slider-thumb-label-showing).cdk-focused.ce-slider-disabled .ce-slider-thumb {
    border-color: rgba(0, 0, 0, 0.26);
}
.ce-slider-has-ticks .ce-slider-wrapper::after {
    border-color: rgba(0, 0, 0, 0.7);
}
.ce-slider-horizontal .ce-slider-ticks {
    background-image: repeating-linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
    );
    background-image: -moz-repeating-linear-gradient(
        0.0001deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
    );
}
.ce-slider-vertical .ce-slider-ticks {
    background-image: repeating-linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7) 2px,
        transparent 0,
        transparent
    );
}
.ce-step-header.cdk-keyboard-focused,
.ce-step-header.cdk-program-focused,
.ce-step-header:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
    .ce-step-header:hover {
        background: none;
    }
}
.ce-step-header .ce-step-label,
.ce-step-header .ce-step-optional {
    color: rgba(0, 0, 0, 0.54);
}
.ce-step-header .ce-step-icon {
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
}
.ce-step-header .ce-step-icon-selected,
.ce-step-header .ce-step-icon-state-done,
.ce-step-header .ce-step-icon-state-edit {
    background-color: var(--primaryColor);
    color: #fff;
}
.ce-step-header .ce-step-icon-state-error {
    background-color: transparent;
    color: #f44336;
}
.ce-step-header .ce-step-label.ce-step-label-active {
    color: rgba(0, 0, 0, 0.87);
}
.ce-step-header .ce-step-label.ce-step-label-error {
    color: #f44336;
}
.ce-stepper-horizontal,
.ce-stepper-vertical {
    background-color: #fff;
}
.ce-stepper-vertical-line::before {
    border-left-color: rgba(0, 0, 0, 0.12);
}
.ce-horizontal-stepper-header::before,
.ce-horizontal-stepper-header::after,
.ce-stepper-horizontal-line {
    border-top-color: rgba(0, 0, 0, 0.12);
}
.ce-horizontal-stepper-header {
    height: 72px;
}
.ce-stepper-label-position-bottom .ce-horizontal-stepper-header,
.ce-vertical-stepper-header {
    padding: 24px 24px;
}
.ce-stepper-vertical-line::before {
    top: -16px;
    bottom: -16px;
}
.ce-stepper-label-position-bottom .ce-horizontal-stepper-header::after,
.ce-stepper-label-position-bottom .ce-horizontal-stepper-header::before {
    top: 36px;
}
.ce-stepper-label-position-bottom .ce-stepper-horizontal-line {
    top: 36px;
}
.ce-sort-header-arrow {
    color: #757575;
}
.ce-tab-nav-bar,
.ce-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ce-tab-group-inverted-header .ce-tab-nav-bar,
.ce-tab-group-inverted-header .ce-tab-header {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
}
.ce-tab-label,
.ce-tab-link {
    color: rgba(0, 0, 0, 0.87);
}
.ce-tab-label.ce-tab-disabled,
.ce-tab-link.ce-tab-disabled {
    color: rgba(0, 0, 0, 0.38);
}
.ce-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.87);
}
.ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.38);
}
.ce-tab-group[class*="ce-background-"] .ce-tab-header,
.ce-tab-nav-bar[class*="ce-background-"] {
    border-bottom: none;
    border-top: none;
}
.ce-tab-group.ce-primary .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-primary .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-primary .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-primary .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-primary .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-primary .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-primary .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-primary .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled) {
    background-color: rgba(209, 196, 233, 0.3);
}
.ce-tab-group.ce-primary .ce-ink-bar,
.ce-tab-nav-bar.ce-primary .ce-ink-bar {
    background-color: var(--primaryColor);
}
.ce-tab-group.ce-primary.ce-background-primary .ce-ink-bar,
.ce-tab-nav-bar.ce-primary.ce-background-primary .ce-ink-bar {
    background-color: #fff;
}
.ce-tab-group.ce-accent .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-accent .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-accent .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-accent .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-accent .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-accent .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-accent .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-accent .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled) {
    background-color: rgba(255, 229, 127, 0.3);
}
.ce-tab-group.ce-accent .ce-ink-bar,
.ce-tab-nav-bar.ce-accent .ce-ink-bar {
    background-color: #ffd740;
}
.ce-tab-group.ce-accent.ce-background-accent .ce-ink-bar,
.ce-tab-nav-bar.ce-accent.ce-background-accent .ce-ink-bar {
    background-color: rgba(0, 0, 0, 0.87);
}
.ce-tab-group.ce-warn .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-warn .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-warn .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-warn .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-warn .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-warn .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-warn .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-warn .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled) {
    background-color: rgba(255, 205, 210, 0.3);
}
.ce-tab-group.ce-warn .ce-ink-bar,
.ce-tab-nav-bar.ce-warn .ce-ink-bar {
    background-color: #f44336;
}
.ce-tab-group.ce-warn.ce-background-warn .ce-ink-bar,
.ce-tab-nav-bar.ce-warn.ce-background-warn .ce-ink-bar {
    background-color: #fff;
}
.ce-tab-group.ce-background-primary .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-primary .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-primary .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-primary .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-primary .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-primary .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-primary .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-primary .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled) {
    background-color: rgba(209, 196, 233, 0.3);
}
.ce-tab-group.ce-background-primary .ce-tab-header,
.ce-tab-group.ce-background-primary .ce-tab-links,
.ce-tab-group.ce-background-primary .ce-tab-header-pagination,
.ce-tab-nav-bar.ce-background-primary .ce-tab-header,
.ce-tab-nav-bar.ce-background-primary .ce-tab-links,
.ce-tab-nav-bar.ce-background-primary .ce-tab-header-pagination {
    background-color: var(--primaryColor);
}
.ce-tab-group.ce-background-primary .ce-tab-label,
.ce-tab-group.ce-background-primary .ce-tab-link,
.ce-tab-nav-bar.ce-background-primary .ce-tab-label,
.ce-tab-nav-bar.ce-background-primary .ce-tab-link {
    color: #fff;
}
.ce-tab-group.ce-background-primary .ce-tab-label.ce-tab-disabled,
.ce-tab-group.ce-background-primary .ce-tab-link.ce-tab-disabled,
.ce-tab-nav-bar.ce-background-primary .ce-tab-label.ce-tab-disabled,
.ce-tab-nav-bar.ce-background-primary .ce-tab-link.ce-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
}
.ce-tab-group.ce-background-primary .ce-tab-header-pagination-chevron,
.ce-tab-nav-bar.ce-background-primary .ce-tab-header-pagination-chevron {
    border-color: #fff;
}
.ce-tab-group.ce-background-primary .ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron,
.ce-tab-nav-bar.ce-background-primary .ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
}
.ce-tab-group.ce-background-primary .ce-ripple-element,
.ce-tab-nav-bar.ce-background-primary .ce-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
}
.ce-tab-group.ce-background-accent .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-accent .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-accent .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-accent .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-accent .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-accent .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-accent .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-accent .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled) {
    background-color: rgba(255, 229, 127, 0.3);
}
.ce-tab-group.ce-background-accent .ce-tab-header,
.ce-tab-group.ce-background-accent .ce-tab-links,
.ce-tab-group.ce-background-accent .ce-tab-header-pagination,
.ce-tab-nav-bar.ce-background-accent .ce-tab-header,
.ce-tab-nav-bar.ce-background-accent .ce-tab-links,
.ce-tab-nav-bar.ce-background-accent .ce-tab-header-pagination {
    background-color: #ffd740;
}
.ce-tab-group.ce-background-accent .ce-tab-label,
.ce-tab-group.ce-background-accent .ce-tab-link,
.ce-tab-nav-bar.ce-background-accent .ce-tab-label,
.ce-tab-nav-bar.ce-background-accent .ce-tab-link {
    color: rgba(0, 0, 0, 0.87);
}
.ce-tab-group.ce-background-accent .ce-tab-label.ce-tab-disabled,
.ce-tab-group.ce-background-accent .ce-tab-link.ce-tab-disabled,
.ce-tab-nav-bar.ce-background-accent .ce-tab-label.ce-tab-disabled,
.ce-tab-nav-bar.ce-background-accent .ce-tab-link.ce-tab-disabled {
    color: rgba(0, 0, 0, 0.4);
}
.ce-tab-group.ce-background-accent .ce-tab-header-pagination-chevron,
.ce-tab-nav-bar.ce-background-accent .ce-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.87);
}
.ce-tab-group.ce-background-accent .ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron,
.ce-tab-nav-bar.ce-background-accent .ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.4);
}
.ce-tab-group.ce-background-accent .ce-ripple-element,
.ce-tab-nav-bar.ce-background-accent .ce-ripple-element {
    background-color: rgba(0, 0, 0, 0.12);
}
.ce-tab-group.ce-background-warn .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-warn .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-warn .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-group.ce-background-warn .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-warn .ce-tab-label.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-warn .ce-tab-label.cdk-program-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-warn .ce-tab-link.cdk-keyboard-focused:not(.ce-tab-disabled),
.ce-tab-nav-bar.ce-background-warn .ce-tab-link.cdk-program-focused:not(.ce-tab-disabled) {
    background-color: rgba(255, 205, 210, 0.3);
}
.ce-tab-group.ce-background-warn .ce-tab-header,
.ce-tab-group.ce-background-warn .ce-tab-links,
.ce-tab-group.ce-background-warn .ce-tab-header-pagination,
.ce-tab-nav-bar.ce-background-warn .ce-tab-header,
.ce-tab-nav-bar.ce-background-warn .ce-tab-links,
.ce-tab-nav-bar.ce-background-warn .ce-tab-header-pagination {
    background-color: #f44336;
}
.ce-tab-group.ce-background-warn .ce-tab-label,
.ce-tab-group.ce-background-warn .ce-tab-link,
.ce-tab-nav-bar.ce-background-warn .ce-tab-label,
.ce-tab-nav-bar.ce-background-warn .ce-tab-link {
    color: #fff;
}
.ce-tab-group.ce-background-warn .ce-tab-label.ce-tab-disabled,
.ce-tab-group.ce-background-warn .ce-tab-link.ce-tab-disabled,
.ce-tab-nav-bar.ce-background-warn .ce-tab-label.ce-tab-disabled,
.ce-tab-nav-bar.ce-background-warn .ce-tab-link.ce-tab-disabled {
    color: rgba(255, 255, 255, 0.4);
}
.ce-tab-group.ce-background-warn .ce-tab-header-pagination-chevron,
.ce-tab-nav-bar.ce-background-warn .ce-tab-header-pagination-chevron {
    border-color: #fff;
}
.ce-tab-group.ce-background-warn .ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron,
.ce-tab-nav-bar.ce-background-warn .ce-tab-header-pagination-disabled .ce-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, 0.4);
}
.ce-tab-group.ce-background-warn .ce-ripple-element,
.ce-tab-nav-bar.ce-background-warn .ce-ripple-element {
    background-color: rgba(255, 255, 255, 0.12);
}
.ce-toolbar {
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.87);
}
.ce-toolbar.ce-primary {
    background: var(--primaryColor);
    color: #fff;
}
.ce-toolbar.ce-accent {
    background: #ffd740;
    color: rgba(0, 0, 0, 0.87);
}
.ce-toolbar.ce-warn {
    background: #f44336;
    color: #fff;
}
.ce-toolbar .ce-form-field-underline,
.ce-toolbar .ce-form-field-ripple,
.ce-toolbar .ce-focused .ce-form-field-ripple {
    background-color: currentColor;
}
.ce-toolbar .ce-form-field-label,
.ce-toolbar .ce-focused .ce-form-field-label,
.ce-toolbar .ce-select-value,
.ce-toolbar .ce-select-arrow,
.ce-toolbar .ce-form-field.ce-focused .ce-select-arrow {
    color: inherit;
}
.ce-toolbar .ce-input-element {
    caret-color: currentColor;
}
.ce-toolbar-multiple-rows {
    min-height: 64px;
}
.ce-toolbar-row,
.ce-toolbar-single-row {
    height: 64px;
}
@media (max-width: 599px) {
    .ce-toolbar-multiple-rows {
        min-height: 56px;
    }
    .ce-toolbar-row,
    .ce-toolbar-single-row {
        height: 56px;
    }
}
.ce-tooltip {
    background: rgba(97, 97, 97, 0.9);
}
.ce-tree {
    background: #fff;
}
.ce-tree-node,
.ce-nested-tree-node {
    color: rgba(0, 0, 0, 0.87);
}
.ce-tree-node {
    min-height: 48px;
}
.ce-snack-bar-container {
    color: rgba(255, 255, 255, 0.7);
    background: #323232;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.ce-simple-snackbar-action {
    color: #ffd740;
}
