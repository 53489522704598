.footer-secondary {
    @include secondary-footer-sizes;
    background-color: GetPrimaryColor('grey-dark');
    color: GetPrimaryColor('white-regular');
    font: 0.64rem/1rem "OpenSans";
    text-align: center;
    padding: 1rem 0rem;
    z-index: 1;
    & [class^='col-']:first-child {
      margin-bottom: 1rem;
    }
    @include md {
      text-align: initial;
      // font: 1rem/1.555rem "OpenSans";
      & [class^='col-']:first-child {
        margin-bottom: 0rem;
      }
      div {
        align-self: center;
      }
    }
}
app-footer {
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: end;
}
.subitems{
  cursor: pointer;
}
