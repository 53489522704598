
.structure-list {
    .k-grid {
        @include body2-sizes;
        td {
            @include body2-sizes;
        }
    }
    .k-grid-header {
        color: GetPrimaryColor('grey-dark');
        background-color: GetPrimaryColor('white-regular');
        .k-header {
            border-bottom: 2px solid GetOtherColor('grey-dark');
            @include body2-sizes;
            font-weight: 600;
        }

    }
    // Prevent hover color from kendo grid
    .k-grid tr:hover {
        background: transparent;
    }
    .i-text {
        font-size: 1.1111rem !important;
        text-decoration: underline !important;
    }
}
.structure-search {
    .k-grid {
        @include body2-sizes;
        td {
            @include body2-sizes;

        }
        a:hover {
            cursor: pointer;
        }
    }
    .k-grid-header {
        color: GetPrimaryColor('grey-dark');
        background-color: GetPrimaryColor('white-regular');
        .k-header {
            border-bottom: 2px solid GetOtherColor('grey-dark');
            @include body2-sizes;
            font-weight: 600;
        }
    }
    svg {
        display: inline-block;
        stroke-width: 0.3rem;
        height: 1rem;
        width: 0.85rem;
        vertical-align: bottom;
        margin-left: 10px;
    }
    .search-icon {
        color: GetPrimaryColor('blue-regular');
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 1;
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
        &:hover {
            cursor: pointer;
        }
    }
    .highlight {
        background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
        @include transitions-slow;
        &.k-alt {
            background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
        }
        &:hover, &.k-alt:hover {
            background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
        }

    }
    .no-highlight {
        @include transitions-slow;
        background-color: transparent !important;
    }
}
.structures-mobile {
    margin-bottom: 1rem;
}
a.btn-flex {
    align-items: baseline;
}
a.btn-flex-disabled {
    color: GetSecondaryColor('grey-dark') !important;
}
.structure-search-mobile, .structures-mobile {
    display: flex;
    flex-direction: column;
    .structure {
        &:not(:last-child) {
            margin-bottom: 1rem;
            border-bottom: 2px solid GetOtherColor('grey-light');
        }
        .structure-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > *:not(:last-child) {
                width: 33.333%;
            }
        }
        .structure-options-row {
            display: flex;
            align-items: center;
            align-content: stretch;
            justify-content: space-between;
            .btn {
                min-width: 0 !important;
            }

            .icon-trash {
                font-size: 2rem;
                padding-top: 1rem;
                color: GetSecondaryColor('red-regular');
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
