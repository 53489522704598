
.btn {
  min-width: 7.5rem;
  // width: 100%;
  border: none;
  display: inline-block;
  text-decoration: none;
  border-radius: 2.25rem;
  padding: 0.875rem 1rem;
  font: 1rem/1.555rem "OpenSans-Bold", sans-serif;
  color: GetPrimaryColor('white-regular');
  display: flex;
  align-items: center;
  // justify-content: center;
  &.override {
    width: 100%;
    height: 100%;
  }
  .icon {
    line-height: 0;
    font-size: 2.222rem;
  }
  @include transitions;
  @include md {
    border-radius: 2.25rem;
    padding: 1rem 1.555rem;
  }
  &.btn-sm {

  }
  &-primary {
    background-color: GetPrimaryColor("blue-regular");

    @include hover-state {
      background-color: GetPrimaryColor("blue-dark") !important;
    }
  }
  &-secondary {
    background-color: GetSecondaryColor("green-button");
    @include hover-state {
      background-color: GetSecondaryColor("green-button-hover") !important;
    }
  }
  &-primary-outline {
    background-color: transparent;
    border: 2px solid GetPrimaryColor("blue-dark");
    color: GetPrimaryColor("blue-dark");
    @include hover-state {
      background-color: GetPrimaryColor("blue-dark");
      color: GetPrimaryColor("white-regular");
      border: 2px solid GetPrimaryColor("blue-dark");
    }
  }
  &-primary-outline-blue {
    background-color: GetPrimaryColor("blue-regular");
    border: 2px solid GetPrimaryColor("white-regular");
    @include hover-state {
      background-color: GetPrimaryColor("blue-dark");
      border: 2px solid GetPrimaryColor("white-regular");
    }
  }
  &-primary-outline-white {
    background-color: transparent;
    color: GetPrimaryColor("white-regular");
    border: 2px solid GetPrimaryColor("white-regular");
    @include hover-state {
      color: GetSecondaryColor("grey-regular");
      border: 2px solid GetPrimaryColor("grey-regular");

    }
  }
  &-danger {
    background-color: GetSecondaryColor("red-regular");
    color: GetPrimaryColor("white-regular");
    font: 1rem/1.5rem "OpenSans-Bold", sans-serif;
    @include hover-state {
      background-color: darken($color: GetSecondaryColor("red-regular"), $amount: 20);
    }
  }
  &-danger-outline {
    background-color: transparent;
    border: 2px solid GetSecondaryColor("red-dark");
    color: GetSecondaryColor("red-dark");
    font: 1rem/1.5rem "OpenSans-Bold", sans-serif;
    @include hover-state {
      background-color: GetSecondaryColor("red-dark");
      color: GetPrimaryColor("white-regular");
      border: 2px solid GetPrimaryColor("red-dark");
    }
  }
  &-link {
    background-color: transparent;
    border: none;
    text-transform: none;
    font: 1rem/1.5rem "OpenSans-Bold", sans-serif;
    color: GetPrimaryColor("blue-dark");
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    display: flex;
    align-items: center;
    .text{
      text-decoration: underline;

    }
    @include hover-state {
      color: GetSecondaryColor("blue-dark");
    }
    &:visited {
      color: GetPrimaryColor("blue-regular");
    }
    .add::before {
      content: '\002B';
      font-size: 2.222rem;
    }

  }
  &-footer-link {
    background-color: transparent;
    border: none;
    text-transform: none;
    font-weight: normal;
    color: GetPrimaryColor("white-regular");
    outline: none;
    border: none;
    font-size: 1rem;
    text-decoration: none;
    box-shadow: none;
    @include hover-state {
      color: GetPrimaryColor("grey-light") !important;
    }
  }
  &-danger-link {
    background-color: transparent;
    border: none;
    text-transform: none;
    color: GetSecondaryColor("red-regular") !important;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    @include hover-state {
      color: darken($color: GetSecondaryColor("red-regular"), $amount: 20);
    }
    .text {
      text-decoration: underline;
    }
  }
  &-text {
    background-color: transparent;
    color: GetPrimaryColor("grey-dark");
    text-transform: none;
    padding: 0;
    @include body2-sizes;
    @include hover-state {
      color: GetPrimaryColor("blue-regular")
    }
  }
  &-grey {
    background-color: GetSecondaryColor("grey-regular");
    border: 2px solid GetSecondaryColor("grey-regular");
    font: 1rem/1.5rem "OpenSans-Bold", sans-serif;
    color: GetPrimaryColor("white-regular");
    @include hover-state {
      background-color: GetPrimaryColor("grey-light");
    }
  }

  &:disabled {
    opacity: 1;
    color: GetSecondaryColor("grey-regular");
    background-color: GetOtherColor("grey-dark");
    border: none;
    outline: none;
    cursor: not-allowed;
    @include hover-state {
      color: GetSecondaryColor("grey-regular") !important;
      background-color: GetOtherColor("grey-dark") !important;
    }
  }
  .icon::before {
    font-weight: 500;
  }
}
.btn:focus {
  outline: 0;
  box-shadow: 0;
}
