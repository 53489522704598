.row .col-auto.align-text {
    display: flex;
    align-items: baseline;
    h4 {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
}
.access-info.row, .flush-info.row {
    border-bottom: 1px solid GetPrimaryColor('grey-light');
    margin-bottom: 0.5rem;
    padding: 1rem 0rem;
    i {
        font-size: 55px;
        color: GetPrimaryColor('blue-regular');
    }
}
.last-row.row {
    padding: 1rem 0rem;
}
.customer-contact {
    p:not(:last-child) {
        margin-bottom: 0.25rem;
    }
    p:last-child {
        margin-bottom: 0.5rem;
    }
}
.flush-info.row .icon-insect {
    display: block;
    background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    margin-top: 10px;
}
.review-btns.row > * {
    margin-bottom: 1.25rem;
}