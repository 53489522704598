$primary-color: GetPrimaryColor("blue-regular");

.requests-my-requests-header {
  margin-bottom: 1.555rem;
}

@-webkit-keyframes spin {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(1080deg);}
}

.request-refresh { 
  text-align-last: center;
  // The width and height is needed for the animation to process correctly
  width: 2.25rem;
  height: 2.25rem;
  font-size: 2.5rem;
  color: $primary-color;
  @include lg {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 3rem;
  }
}

.refresh-animation {
  -webkit-animation: spin 2s 1 ease;
  display: block;
}

.requests-wrapper {
  display: flex;
  font-family: "OpenSans";
  flex-direction: column;
  .k-grid {
    @include body-sizes;
    td {
      @include body-sizes;
    }
    table tbody tr {
      @include transitions-slow;
      &:hover {
        background: GetSecondaryColor("blue-grey-light");
      }
    }
  }
  .k-grid-header {
    .k-header {
      @include body2-sizes;
      font-weight: 600;
    }
  }
}

.requests-wrapper .form-group {
  width: 100%;
}


.requests-wrapper > div.ng-star-inserted > .row > div.ng-star-inserted {
  width: 100%;
}


// .requests-wrapper > .ng-star-inserted:nth-of-type(2) {
//   width: 100%;
// }


.k-tabstrip h2 {
  margin-bottom: 5px;
}
.k-tabstrip-items .k-link {
  color: GetPrimaryColor("grey-dark");
}

.k-tabstrip > .k-content {
  border-color: transparent;
}

.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
  border-bottom-color: $primary-color;
  border-width: medium;
}

.k-tabstrip-items .k-item.k-state-active,
.k-tabstrip-items .k-item.k-state-selected {
  border-color: transparent;
  text-underline-position: below;
}

.flush-request-button {
  margin: 10px;
}

div > .flush-request-button > a {
  text-align: center;
  &.btn {
    border: 1px solid $primary-color;
    color: $primary-color;
  }
  &.btn-primary {
    background-color: transparent;
  }
}

.k-grid-header .k-header {
  font-size: 14px;
  font-family: "OpenSans-Bold";
}

.k-grid td,
.k-grid-content tr:last-child > td,
.k-grid-content-locked tr:last-child > td {
  border-bottom-width: thin;
  border-color: transparent;
  border-bottom-color: GetPrimaryColor("grey-dark");
}

@media (max-width: 1023px) {
  .display-grid-view {
    display: none;
  }
}

@media (min-width: 1024px) {
  .display-panel-view {
    display: none;
  }
}

.create-wr-search-wrapper {
  display: inline-flex;
  justify-content: center;
  @include lg {
    justify-content: end;
  }
}

.search-icon {
  white-space: nowrap;
  position: absolute;
  right: 0.6rem;
  top: 1.1rem;
  color: #2A2A2A;
  cursor: pointer;
  i {
    font-size: 3rem;
  }
}

.inactive-icon {
  opacity: 18%;
}

.search-form-field {
  display: flex;
}

.center-text-lg {
  text-align: center;
  @include lg {
    text-align: unset;
  }
}

.table-links {
  font-family: "OpenSans-Bold", Arial, sans-serif;
  font-weight: bold;
  text-decoration: underline !important;
  color: #0078cf !important;
  text-transform: none;
}

cache {
  position: absolute;
  z-index: -1000;
  opacity: 0;
}

cache image {
  position: absolute;
}


.map-wrapper {
  width: auto !important;
}