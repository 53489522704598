.photos {
    .video-section {
        height: 100%;
        width: 100%;
        justify-content: center !important;
        display: flex !important;
        flex-direction: column !important;                                                  
    }
    .photo-section {
        height: 100%;
        width: 100%;
        justify-content: center !important;
        display: flex !important;
        flex-direction: column !important;
    }
    &.camera {
        overflow: hidden;
        max-height: 100%;
    }
    .form-group.row > * {
        margin-bottom: 1.25rem;
    }
    .carousel-left-btn {
        position: absolute;
        background-color: GetPrimaryColor('white-regular');
        outline: GetSecondaryColor('grey-light') 0.5px solid;
        color: GetSecondaryColor('grey-regular');
        height: 50px;
        width: 50px;
        bottom: calc((100% / 2) - 50px);
        font-weight: 600;
        font-size: 1.785rem;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        left: 1%;
    }
    .carousel-right-btn {
        position: absolute;
        background-color: GetPrimaryColor('white-regular');
        outline: GetSecondaryColor('grey-light') 0.5px solid;
        color: GetSecondaryColor('grey-regular');
        height: 50px;
        width: 50px;
        bottom: calc((100% / 2) - 50px);
        font-weight: 600;
        font-size: 1.785rem;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        right: 1%;
    }
    @include md {
        .carousel-left-btn,.carousel-right-btn {
            height: 85px;
            width: 85px;
            font-size: 3rem;
            bottom: calc((100% / 2) - 85px);
        }
    }
    .pageRefreshLink {
        color: GetPrimaryColor('blue-light');
        text-decoration: none;
    }
    .processing-btn {
        height: 100%;
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: GetSecondaryColor("blue-grey-light");
    }
    .play-btn-img {
      object-fit: cover;
      width: 50px;
      height: 100px;
      min-width: 100%;
      min-height: 100%;
    }
    .play-btn {
        background-color: GetSecondaryColor("blue-grey-light");
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        img {
            opacity: 50%;
        }
        div {
            position: absolute;
            left: 35%;
            box-sizing: border-box;
            height: 74px;
            
            border-color: transparent transparent transparent GetPrimaryColor('blue-dark');
            transition: 100ms all ease;
            will-change: border-width;
            cursor: pointer;
          
            // play state
            border-style: solid;
            border-width: 37px 0 37px 60px;
        }
    }
}
.photos .link {
    font: 1rem/1.5rem "OpenSans-Bold", sans-serif;
    color: GetPrimaryColor("blue-regular");
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    width: 60px;
    @include transitions;
    @include hover-state {
        color: GetPrimaryColor("blue-dark");
        #edit-icon {
            fill: GetPrimaryColor("blue-dark");
        }
    }
}
.photo {
    position: relative;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 150px;
    max-height: 150px;
    outline: 1px solid GetPrimaryColor("grey-dark");
    @include lg {
        min-height: 220px;
        max-height: 220px;
    }
    img.img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.img-caption-2 {
    color: GetPrimaryColor("grey-dark");
    margin-bottom: 0rem !important;
    display: flex;
    align-items: center;
    .icon {
        font-size: 1.25rem;
        font-weight: 500 !important;
        vertical-align: middle;
        @include icon-sm;
        color: GetPrimaryColor("blue-regular");
    }
}
.img-caption-1 {
    margin-top: 0.5rem;
    margin-bottom: 0rem !important;
}
.take-photo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: GetPrimaryColor("blue-regular");
    border: 2px dashed GetPrimaryColor("blue-regular");
    min-height: 150px;
    max-height: 150px;
    content: "";
    @include lg {
        min-height: 220px;
        max-height: 220px;
    }
    p {
        margin-bottom: 0;
    }
    .icon-camera-photo,
    .icon-question-circle {
        @include icon-lg;
        font-weight: 500;
    }
    &:hover {
        cursor: pointer;
    }
}
.badges {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include lg {
        flex-direction: row;
        align-items: baseline;
    }
}
.label-header {
    background: rgba(GetPrimaryColor("blue-regular"), 0.95);
    border-radius: 50%;
    text-align: center;
    color: GetPrimaryColor("white-regular");
    height: 100%;
    max-height: 28px;
    max-width: 28px;
    vertical-align: middle;
    p {
        margin: 0;
    }
}
.border {
    border-bottom: 1px solid GetPrimaryColor("grey-dark");
    padding-bottom: 1rem;
}
.light-border {
    border-bottom: 1px solid GetPrimaryColor("grey-light");
}
.badge-solid {
    color: GetPrimaryColor("white-regular");
    background: rgba(GetPrimaryColor("blue-regular"), 0.95);
    padding: 0.15rem 0.75rem;
    border-radius: 1rem;
    border: 2px solid rgba(6, 155, 215, 0.95);
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    @include md {
        margin-bottom: 0;
    }
    cursor: pointer;
    @include transitions;
}

.nav-btn-flush-info {
    width: 7.5rem;
    margin: auto;
    @include lg {
        margin: 0;
    }
}

.take-photo-text {
    text-decoration: underline;
    font-weight: 600;
    color: GetPrimaryColor("blue-dark");
}
.photo-comments {
    max-width: 727px;
}
.disabled{
    color: GetSecondaryColor("grey-light");

}
.disabled-border{
    border-color: GetPrimaryColor("grey-light");
}

.vjs_video_1-dimensions.vjs-fluid {
    padding-top: 57% !important;
    @media only screen and (max-width: 420px) {
        padding-top: 100% !important;
    }
}