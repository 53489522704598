.tracker-text {
  display: none;
  @include sm {
    display: inline;
  }
}

.top-tracker-inactive {
  cursor: pointer;
  border-bottom: 1px solid GetPrimaryColor('grey-light');
  border-radius: 0rem;
  padding-bottom: .5rem;
  padding-left: 0rem;
  padding-right: 0rem;
  @include transitions;
  label {
    color: GetSecondaryColor('grey-light');
  }
}

.top-tracker-active {
  cursor: default;
  border-bottom: 4px solid GetPrimaryColor('blue-regular');
  border-radius: 0rem;
  padding: 0rem;
  padding-bottom: .5rem;
  .tracker-text{
    display: inline;
  }
  label {
    color: GetPrimaryColor('blue-regular');
  }
}

.top-tracker-row {
  padding-left: 0rem;
  padding-right: 0rem;
  text-align: center;
  margin-bottom: 2.2222rem;
}

.top-tracker-row-dashboard {
  margin-bottom: 1.11111rem !important;
}

.dashboard-tracker {
  margin-bottom: 0rem;
}

.dashboard-col {
  margin-right: 1rem;
  margin-left: 1rem;
}

.dashboard-col.top-tracker-inactive {
  border-bottom: none;
  h4 {
    @include transitions;
    color: GetSecondaryColor('grey-regular');
  }
  &:hover {
    h4 {
      color: GetSecondaryColor('grey-light');
    }
  }
}
