.carousel-wrapper {
  height: 28rem;
}

ngb-carousel {
  width: 100%;
  height: 100%;
  .sr-only{
    display: none;
  }
}

.carousel-inner {
  overflow: visible;
  height:100%;
  @include xl {
    & .carousel-caption {
      text-align: left;
      color: black;
      left: 107%;
      right: -40%;
      bottom: -7%;
      p {
        margin-bottom: .1rem;
        font-size: 14px;
      }
    }
  }
}

.carousel-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease-in-out, visibility 0.5s;
  z-index: -1;
  width: 100%;
  height:100%;
  .carousel-image {
    height: 100%;
    width: 100%;
    .image-wrapper {
      padding-bottom: 100%;
      width: 100%;
      @include xl {
        padding-bottom: 62.58%;
      }
      img {
        position: absolute;
        top: 0%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        filter:brightness(50%);
        @include xl {
          filter: brightness(100%);
          height: 135%;
          top: auto;
          bottom: -3.5%;
        }
      }
    }
  }

}
.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  transform: translateX(0); 
}
.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(0);
}
.carousel-item.active{
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

.carousel-control-prev {
  z-index: 20;
}


.carousel-control-next {
  z-index: 20;
}

.carousel-indicators {
  li{
    background-color: GetOtherColor('grey-dark');
    height: 0.23rem;
    width: 1.85rem;
  }
  li.active {
    background-color: GetPrimaryColor('blue-regular');
  }
}

.mobile-view{
  padding-left: 0px;
  padding-right: 0px;
  top: -7px;
  @include xl {
    padding-left: 12px;
    padding-right: 12px;
  }
}
