// Conversions: px as rem
$zero: 0;
$one: 0.0625rem;
$two: 0.125rem;
$four: 0.25rem;
$eight: 0.5rem;
$twelve: 0.75rem;
$sixteen: 1rem;
$twenty: 1.25rem;
$twenty-four: 1.5rem;
$twenty-eight: 1.75rem;
$thirty-two: 2rem;
$thirty-six: 2.25rem;
$fourty-eight: 3rem;
$sixty: 3.75rem;
$forty-eight: 3rem;
$sixty-four: 4rem;
$eighty: 5rem;
$ninety-six: 6rem;
$one-one-two: 7rem;
$one-twenty-eight: 8rem;

// Grid spacing
$grid-spacing: 20px; // 1.1rem

$w-100: 100%;
$w-90: 90%;
$w-80: 80%;
$w-70: 70%;
$w-60: 60%;
$w-50: 50%;
$w-40: 40%;
$w-30: 30%;
$w-20: 20%;
$w-15: 15%;
$w-10: 10%;

$none: none;
