.card {
    background: GetPrimaryColor('white-regular');
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    .card-body {
        flex: 1 1 auto;
        padding: 1rem;
    }
}
.large-card-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    @include transitions-slow;
    .large-card {
        position: absolute;
        height: 100%;
        top: 0;
        width: 100%;
        z-index: 1000;
        background: GetPrimaryColor('white-regular');
        color: GetPrimaryColor('grey-dark');
        box-shadow: 0 0.75rem 2rem -1rem rgba(GetPrimaryColor("grey-dark"), 0.4);
        transform: scale(0);
        opacity: 0;
        transition: height 0.45s ease, transform 0.35s ease-in-out, opacity 0.3s ease-out;
        @include md {
            width: 100%;
            height: inherit;
            left: 0;
        }
        &.unhide {
            // height: 0;
            transform: scale(1);
            opacity: 1;
        }
        .large-card-header {    
            padding-top: 100%;  // Mobile aspect ratio 1:1 to help with tagging in portrait mode
            position: relative;
            text-align: center;
            img {
                object-fit: contain;
                display: initial !important;
            }
            @include md {
                padding-top: 56.25%; // Tablet size 4:3
            }
            @include lg {
                padding-top: 56.25%; // Anything bigger goes to 16:9
            }
        }
        .large-card-body {
            background: GetPrimaryColor('white-regular');
            padding: 1rem 1.5rem;
        }
        .close-card {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            color: #ffffff;
            text-align: end;
            background: rgba(GetPrimaryColor("grey-dark"),.45);
            .just-icon {
                @include icon-lg;
                color: GetPrimaryColor("white-regular");
                font-weight: 400 !important;

            }
            @include lg {
                .just-icon {
                    font-size: 5rem !important;
                }
            }
        }
    }
}