.select-box-wrapper {
    color: GetPrimaryColor("grey-dark");
    @include input-field-label;
    .select-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.19rem 0rem 0.2rem;
        border-bottom: 2px solid GetPrimaryColor('grey-light');
        // Set background to light grey
        background-color: GetOtherColor('grey-light');
        position: relative;
        //Add hover statue: Light blue and blue Icon
        &:hover{
            background-color: GetSecondaryColor('blue-grey-light');
        }
        .icon {
            color: GetPrimaryColor('blue-regular');
            @include icon-md;
        }
        .select-label {
            //position: absolute;
            left: 0;
            padding: 0rem 0.5rem;
            top: 1.5rem;
            cursor: text;
            overflow: hidden;
            color: GetSecondaryColor('grey-light');
            z-index: 1;
            transition: transform 150ms ease, font-size 150ms ease;
            @include input-field-label;
            font-weight: 600;
            &.focused {
                transform: translateY(-97%); // Moved down to match textbox
                font-size: .64em;
                color: GetSecondaryColor('grey-dark');
            }
            @include lg {
                padding: 0rem 1rem;
            }
        }

        .select-label-after{
            padding: 0rem 1rem;
            color: GetPrimaryColor('grey-dark');
            @include lg {
                padding: 0rem 1rem;
            }
        }

        .top-select-label{
            position: absolute;
            color: GetSecondaryColor('grey-dark');
            padding: 0rem 1rem;
            font-size: 0.64em;
            top:0px;
        }


        &:focus {
            outline: none;
        }
        &.focused {
            outline: none;
            //border-color: transparent;
            // Add green line and bottom
            border-bottom: 2px solid GetSecondaryColor("green-button-hover");
        }
    }

// Adding error statue
    .errorState{
        &.select-box{
            background-color: GetSecondaryColor('red-light');
            border-bottom: 2px solid GetSecondaryColor('red-regular');
            .select-label{
                color: GetSecondaryColor('red-regular');
                .focused {
                  color: GetSecondaryColor('reg-dark');
                }
            }
            .icon{
                color: GetPrimaryColor('blue-regular');
            }
        }
    }
// Completion tab
    .completeState{
        &.select-box{
            background-color: GetSecondaryColor('blue-grey-light');
            border-bottom: 2px solid GetSecondaryColor("green-button-hover");
            .icon{
                color: GetPrimaryColor('blue-regular');
            }
        }

    }


    .options {
        position: absolute;
        width: 100%;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
        //TODO:  add shadow
        left: 0;
        display: flex;
        flex-direction: column;
        z-index: 2;
        // Add scroll
        overflow-y: auto;
        // height:150px;
        max-height: 200px;
        &.hidden {
            display: none;

        }
        .option {
            &::after {
                display: block;
                position: absolute;
                content: '';
                //border-bottom: 3px solid GetPrimaryColor('blue-regular');
                width: 0%;
                left: 50%;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;

            }
            &:hover, &.hover {
                background-color: GetSecondaryColor("blue-button-hover");
                &::after {
                    width: 100%;
                    left:0;
                }
            }

        }
        .icon{
            font-size: 1.25rem;
            vertical-align: sub;
            color: GetPrimaryColor("blue-regular");
        }
    }

    .error-msg {
      font-weight: 600;
      display: flex;
      align-items: center;
      color: GetPrimaryColor('grey-dark');
      // padding: 0.1rem 0.75rem 0rem .75rem;

    }

    .error-icon {
      color: GetSecondaryColor('red-dark');
      font-weight: 500;
      font-size: 1.25rem;
    }

    .selectbox-relative-selection {
      position: relative;
    }
}


.hide-selectbox-error {
  display: none !important;
}

.select-box-caret {
  font-size: 1.875rem !important;
}
