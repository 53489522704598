.construction-crew-panel-bar-actions-wrapper {
  color:#0581C1;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.construction-crew-panel-bar-actions-span {
  text-decoration:underline;
  font-size: 1rem;
  font-weight: 500;
}

.construction-crew-panel-bar-right-arrow {
  color:#0581C1;
  text-decoration:none;
  margin-left: 0.25rem;
}
