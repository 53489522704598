/*
    COLORS
    Syntax: $<palette>-<color>(-<optional second color>-)-<shade type>
    palette: primary or secondary
    shade type: light, regular, and dark

    We can always apply the darken() and lighten() methods onto these colors
*/
// =============================================================================
// Primary Color Palette
// =============================================================================
$primary-colors: (
  "blue-light": #0078cf,
  "blue-regular": #0078cf,
  "blue-dark": #026aad,
  "white-regular": #ffffff,
  "grey-light": #dbdbdb,
  "grey-dark": #2a2a2a // Coned website uses #d7d7d7 for its html,body
);
// =============================================================================
// Secondary Color Palette
// =============================================================================
$secondary-colors: (
  "blue-grey-light": #f0f5f9,
  "blue-grey-regular": #c6eaf2,
  "blue-dark": #026aad,
  "blue-button-hover": #C6EAF2,
  "red-light": #FDEEEE,
  "red-regular": #ec5e5e,
  "red-dark": #C85050,
  "yellow-regular": #f9eaa9,
  "green-light": #89cdaa,
  "green-regular": #6aa383,
  "green-button": #60BC7C,
  "green-button-hover": #43a460,
  "grey-light": #979797,
  "grey-regular": #767676,
  "grey-dark": #565656,
  "grey-background": #F2F2F2
);
// =============================================================================
// Other Color Palette
// =============================================================================
$other-colors: (
    "grey-light": #F8F8F8,
    "grey-regular": #EDEDED,
    "grey-dark": #CFCFCF
);
/*
    BREAKPOINTS
    sm - Small tablets and large smartphones
    md - Small tables
    lg - tablets and small desktops/laptops
    xl - large tablets and desktops/laptops

    There are no breakpoints below 576px because we assume to develop in a mobile-first approach
*/
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 2560px;


/*
    TYPOGRAPHY RULES
*/
$base-font-size: 18px;

/*
    TOOLBAR HEIGHTS
*/

$primary-toolbar-height-sm: 60px;
$primary-toolbar-height-lg: 90px;
$secondary-toolbar-height: 2rem;
