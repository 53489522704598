.sortable {
    transition: color 0.3s ease-in-out;
    .item {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
        padding: 18px 20px;
        outline: 1px solid GetPrimaryColor("grey-light");
        background-color: GetPrimaryColor('white-regular');
        display: flex;
        flex-direction: column;
        .title-row  {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .icon-ellipsis {
                background-color: GetPrimaryColor('grey-dark');
                -webkit-mask: url(/assets/icons/drag-drop.svg) no-repeat center;
                mask: url(/assets/icons/drag-drop.svg) no-repeat center;
                width: 100%;
                height: 100%;
            }
            small {
                font-weight: bold !important;
            }
            span.drag-cta {
                &:hover {
                    cursor: grab;
                }
                height: 26px;
                width: 26px;
                text-align: end;
                color: GetPrimaryColor('grey-dark');
                @include lg {
                    text-align: center;
                    height: 36px;
                    width: 36px;
                } 
            }
        }
        .main-content {
            display: flex;
            flex-direction: row;
            .item-header {
                display: flex;
                flex-direction: column;
                align-content: stretch;
                &.item-cta {
                    flex: 0 0;
                    margin-top: 20px;
                    .content-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        i {
                            &.large {
                                font-size: 2.75rem;
                            }
                            &:not(:last-child) {
                                margin-top: 20px;
                            }
                            &.clickable {
                                &:hover {
                                    cursor: pointer;

                                }
                            }
                            font-size: 1.75rem;
                        }
                        span.line-connect:not(:first-child) {
                            &::before {
                                content: ' ';
                                border-bottom: 2px solid GetPrimaryColor('grey-dark');
                                width: 10%;
                                height: 50%;
                                position: absolute;
                                left: 15%;
                                bottom: 15%;
                                border-left: 2px dashed GetPrimaryColor('grey-dark');
                                border-top: 2px solid GetPrimaryColor('grey-dark');
                            }
                            @include lg {
                                &::before {
                                    content: ' ';
                                    border-bottom: 2px solid GetPrimaryColor('grey-dark');
                                    width: 10%;
                                    height: 60%;
                                    position: absolute;
                                    left: 15%;
                                    bottom: 15%;
                                    border-left: 2px dashed GetPrimaryColor('grey-dark');
                                    border-top: 2px solid GetPrimaryColor('grey-dark');
                                }
                            }
                        }

                    }
                }
                &.hide {
                    display: none;
                }
                .content-item {
                    .arrow-button {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        button {
                            height: 100%;
                            width: 100%;
                            &:focus, &:target, &:active {
                                outline: 5px auto #000000;
                            }
                            i.tile-action {
                                vertical-align: middle;
                                font-size: 1.775rem;
                            }
                        }
                    }
                }
            }
            .item-content {
                flex-grow: 1;
                .content-item {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-evenly;
                    &.align-self {
                        align-self: center;
                    }
                    > i {
                        font-size: 1.75rem;
                    }
                    > *:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
        // Visual Indicator that tiles are sortable
        &.active[aria-grabbed = "true"] {
            outline: 3px solid GetPrimaryColor("blue-dark");
            cursor: grabbing;
            .icon-ellipsis {
                background-color: GetPrimaryColor('blue-dark');
            }
        }
        &.active[aria-grabbed = "false"].k-state-focused {
            outline: 3px solid GetPrimaryColor("blue-dark");
            .icon-ellipsis {
                background-color: GetPrimaryColor('blue-dark');
            }
        }
    }
}
.ellipsis {
    letter-spacing: -5px;
}
.sortable-help-message {
    margin-bottom: 0.5rem;
}