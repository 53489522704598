.axis-hub-banner {
  .banner-component {
    width: 100vw;
    margin-left: -0.5rem;
    margin-top: -3rem;
    position: relative;
    margin-bottom: 3rem;
    @include sm {
      margin-left: 0;
      margin-top: 0;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.flex.flex-row {
  position: relative;
  display: flex;
  *:not(:last-child) {
    margin-right: 1rem;
  }
}
.close-icons {
    color: #0078CF;
    font-size: 2.5rem;
}

.supervisor-settings {
  ce-modal .ce-modal-content div {
    z-index: unset !important;
  }
  .select-box-wrapper {
    .selectbox-relative-selection {
      .options {
        z-index: 3 !important;
      }
    }
    .select-box.focused {
      z-index: 1 !important;
    }
  }
  .settings-link {
    display: flex;
    justify-content: space-between;
    a {
      flex: 0;

      @include lg {
        flex: 1;
      }  
    }
  }
}

.job-modal {
  .ce-modal-body {
    display: block !important;
  }
}

.supervisor-lists {
  ce-tabs {
    .ce-tab-container {
      padding: 0 !important;
      .ce-tabs {
        li {
          margin: 0;
          &:not(:last-child) {
            margin-right: 18px;
          }
        }
      }
    }
    .ce-pane {
      padding: 1rem 0rem;
    }
  }
}
.action-btn-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.large-btn {
  width: 295px !important;
  margin: 10px;  
  @media screen and (max-width: 767px) {

    width: 325px;

  }
}

.centered {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.axis-map-tutorial {
  left: 110px;
  top: 109px;
  width: 500px;
  position: absolute;
  height: 525px;
  opacity: 90%;
  position: absolute;
  @media screen and (max-width: 767px) {
    left: 0px;
    width: 100%;
    top: 60px;
    height: 65vh;
  }
  @media (min-width: 768px) and (max-width: 819px)  {
    left: 30px;
    top: 90px;
  }
  @media (min-width: 820px) and (max-width: 1000px)  {
    left: 50px;
    top: 90px;
  }
  i[class^='map-btn'] {
    height: 50px;
    width: 50px;
    background: #ffffff;
    color: #0078CF;
    right: 5%;
    bottom: 5%;
    position: absolute;
    border-radius: 50%;
    padding: 15px;
    outline: 1px solid #0078CF;
    &::before {
        font-size: 55px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:hover {
        cursor: pointer;
    }
    &.active {
        background: #0078CF;
        color: #ffffff;
        outline-color: #ffffff;
    }
  }
  .content {
    width: 100%;
    padding: 2.5rem;
    color: #ffffff;
    background: #0078CF;
    height: 85%;
  }
  .action-button{ 
    color: #ffffff;
    background: #0078CF;
    height: 15%;
    .tile-action{
      // position: relative;
      // left: -44px;
      // top: -12px;
      font-size: 45px;
      padding-left: 3px;
      padding-top: 3px;
    }
  }
  .secondary-btn {
    text-align: center;
  }
}