.request-wrapper {
    position:relative;
    margin-bottom: 3rem;
    .request-card {
        width: 100%;
        padding: 2rem 1rem;
        @include md {
            margin: 0 !important;
            width: 83.333333%;
            padding: 2rem;
        }
    }
}
.flush-info-container {
  margin: 1rem 0rem 1rem 0rem;
  .flush-info-bottom-margin {
    margin-bottom: 1.667rem;
  }
}
.env-card {
  padding: 20px;
  outline: 1px solid GetSecondaryColor('grey-light');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 347px;
}
.flush-info-camera-btn {
  .btn {
    padding-right:1rem;
    padding-left: 1rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
    font-size: 0.778rem;
    @include sm {
      padding-right: 1.167rem;
    }
  }
  .icon {
    font-size: 1.4rem;
  }
  span {
    font-size: 0rem;
    @include sm {
      font-size:0.778rem;
    }
  }
}