// --------------------------------------------------
// Reflex variables
// --------------------------------------------------

// Prefix
// -------------------------
// You can optionally namespace ce classes to avoid clashing with other css frameworks
// e.g. setting $ce-prefix as "ce-" here would cause col-sm-6 to become ce-col-sm-6
// Example: $ce-prefix: ce-;
// By default this is an empty string
$ce-prefix: null !default;

// Legacy support
// -------------------------
// ce adds a lot of css propertie to support older browsers that don't support flexbox layout.
// If you prefer to use this as a purely flexbox grid without legacy support then set this to false
$legacy-support: true !default;

// Responsive visibility helpers
// -------------------------
// ce has some generic visibility classes to hide elements at different breakpoints.
// If you want to disable these then set this to false
$visibility-helpers: true !default;

// Grid
// -------------------------
$ce-columns: 12 !default; // number of columns

// Breakpoints
// -------------------------

$ce-sm: 475px !default;
$ce-md: 768px !default;
$ce-lg: 1024px !default;
$ce-xl: 1440px !default;

$ce-xs-max: ($ce-sm - 1);
$ce-sm-max: ($ce-md - 1);
$ce-md-max: ($ce-lg - 1);
$ce-lg-max: ($ce-xl - 1);

// Spacing
// -------------------------
$ce-grid-spacing: 15px !default;
// Custom properties so others can reference the variables.
:root {
  --ce-columns: #{$ce-columns};
  --ce-grid-spacing: #{$ce-grid-spacing};
  --ce-sm: #{$ce-sm};
  --ce-md: #{$ce-md};
  --ce-lg: #{$ce-lg};
  --ce-xl: #{$ce-xl};
  --ce-sm-max: #{$ce-sm-max};
  --ce-md-max: #{$ce-md-max};
  --ce-lg-max: #{$ce-lg-max};
}


// variables
// This is used to convert px to rem

$zero: 0;
$two: 0.125rem;
$four: 0.25rem;
$eight: 0.5rem;
$twelve: 0.75rem;
$sixteen: 1rem;
$twenty: 1.25rem;
$twenty-four: 1.5rem;
$twenty-eight:1.75rem;
$thirty-two: 2rem;
$forty-eigh:3rem;
$sixty-four: 4rem;
$eighty: 5rem;
$ninety-six: 6rem;
$one-one-two: 7rem;
$one-twenty-eight: 8rem;

$base-font-size: 1.125rem; // 18px
$font-medium-size: 0.875rem; // 14px
$font-small-size: $twelve;
$body: 1.3125rem;
