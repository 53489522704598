
.mobile-side-items {
    position: fixed;
    top: 0;
    border-bottom: 3px solid GetPrimaryColor('blue-regular');
    background-color: GetPrimaryColor('white-regular');
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 10003;
    .container {
        padding-top: 1rem;
        font: 18px 'Lexend-Semibold';
        .form-group-1 {
            min-height: 55px;
            max-height: 55px;
            &:active, &:focus {
                @include transitions;
                outline: none;
                color: GetPrimaryColor('blue-regular');
                cursor: pointer;
            }
        }
        .flex-items {
            display: flex;
            align-items: center;
        }
    }
    .flew-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        .logo {
            width: 138px;
            height: 28px;
            padding-left: 15px;
        }
        .icon-close {
            font-size: 3rem;
            color: GetPrimaryColor('blue-regular');
        }
    }
}

.mobile-side-items [class^="icon-"], .sub-item [class^="icon-"] {
    font-size: 2.25rem;
}
.mobile-side-items .icon-question-circle, .sub-item .icon-question-circle {
    font-size: 2rem;
    padding-right: 5px;
}
app-navbar + .banner-info {
    svg.icon {
        position: absolute;
        left: 15px;
        height: 50px;
        width: 50px;
        padding-right: 15px;
    }
}
