
%side-nav-item {
    min-height: 100px;
    max-height: 100px;
    padding: 2rem 1rem;
    position: relative;
    &.flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icon {
        font-size: 3rem;
        color: GetSecondaryColor('green-button');
    }
    .router-link {
        text-decoration: none;
        color: inherit;
    }
    .complete {
        font-size: 0.925rem;
        @include transitions-slow;
    }
    :disabled {
        &:hover{
            &.disabled {
                color: GetSecondaryColor('grey-light');
                background-color: GetOtherColor('grey-regular');
                cursor: not-allowed;
                a {
                    cursor: not-allowed;
                }
            }
        }
    }
    &:nth-child(3)::after {
        position: absolute;
        height: 1px;
        content: '';
        left: 0.5rem;
        right: 0.5rem;
        bottom: 0;
        background: GetSecondaryColor('grey-light');
    }
    &:nth-child(4)::after {
        position: absolute;
        height: 1px;
        content: '';
        left: 0.5rem;
        right: 0.5rem;
        bottom: 0;
        background: GetSecondaryColor('grey-light');
    }
    &.needs-separator {
        &::before {
            position: absolute;
            height: 1px;
            content: '';
            left: 0.5rem;
            right: 0.5rem;
            top: 0;
            background: GetSecondaryColor('grey-light');
        }
        &::after {
            height: 0px;
        }
    }
}
.side-nav {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(100% - 83.333333%);
    background-color: GetOtherColor('grey-regular');
        .side-nav-items {
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;
            .side-nav-header {
                background-color: GetPrimaryColor('blue-regular');
                color: GetPrimaryColor('white-regular');
                display: flex;
                flex-direction: column;
                padding: 1rem;
                .heading {
                    font: 1.333rem/1.5rem 'Lexend-Bold';
                }
                & span:first-child {
                    margin-bottom: 1rem;
                }
                & span:nth-child(2) {
                    margin-bottom: 0.5rem;
                }
            }
            .side-nav-item {
                @extend %side-nav-item;
                &.first-item {
                
                }
                &.middle-item{
                    &.hovering {
                        &::before {
                            height: 0px !important;
                        }
                    }
                }
                &.remove-border {
                    &::after {
                        height: 0px !important;
                    }
                }
                &.add-border {
                    &::after {
                        height: 1px !important;
                    }
                }
                &.active {
                    background-color: GetPrimaryColor('white-regular');
                    border-left: 8.5px solid GetSecondaryColor('blue-dark');
                    padding-left: 9.5px;
                    font-weight: 600;
                    border-bottom: none;
                    &::after, ::before {
                        height: 0px;
                    }
                }
                &.disabled {
                    color: GetSecondaryColor('grey-light');
                    background-color: GetOtherColor('grey-regular');
                    cursor: not-allowed;
                    a {
                        cursor: not-allowed;
                    }
                }
                &:nth-child(2):hover {
                    &::after {
                        position: absolute;
                        height: 1px;
                        content: '';
                        left: 0.5rem;
                        right: 0.5rem;
                        bottom: 0;
                        background: GetSecondaryColor('grey-light');
                    }
                    &.active {
                        &::after {
                            height: 0px;
                        }
                    }
                }
                &:nth-child(3):hover {
                    &::after {
                        height: 0px;
                    }
                    &::before {
                        position: absolute;
                        height: 1px;
                        content: '';
                        left: 0.5rem;
                        right: 0.5rem;
                        top: 0;
                        background: GetSecondaryColor('grey-light');
                    }
                    &.active {
                        &::before {
                            height: 0px;
                        }
                    }
                }
                &:nth-child(4):hover {
                    &::after {
                        height: 0px;
                    }
                    &::before {
                        position: absolute;
                        height: 1px;
                        content: '';
                        left: 0.5rem;
                        right: 0.5rem;
                        top: 0;
                        background: GetSecondaryColor('grey-light');
                    }
                    &.active {
                        &::before {
                            height: 0px;
                        }
                    }
                }
                &:hover {
                    @include transitions-slow;
                    background-color: GetPrimaryColor('white-regular');
                    &.disabled {
                        color: GetSecondaryColor('grey-light');
                        background-color: GetOtherColor('grey-regular');
                        cursor: not-allowed;
                        a {
                            cursor: not-allowed;
                        }
                        &::after {
                            height: 1px;
                        }
                        &::before {
                            height: 0px;
                        }
                    }
                }
            }
        }
}
.side-nav-mobile {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    &.offline-offset {
        margin-bottom: 1rem;
    }
    .wrapper {
        position: fixed;
        left: 0;
        right: 0;
        top: 3rem;
        width: 100%;
        z-index: 2;
        &.offline-banner-offset {
            top: 3px;
            padding-top: 13.5rem !important;
        }
        .side-nav-button {
            position: relative;
            background: GetPrimaryColor('white-regular');
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;
            border-bottom: 1px solid GetPrimaryColor('grey-light');
            @include transitions-slow;
            & .close-wrapper {
                position: absolute;
                color: GetPrimaryColor('blue-regular');
                height: 2rem;
                width: 2rem;
                top: 0.75rem; //calc((100% - 2rem) / 2);
                right: 15px;
                .icon {
                    font-size: 2rem;
                }
            }
            &.show {
                background-color: GetPrimaryColor('blue-regular');
                color: GetPrimaryColor('white-regular');
                padding: 2rem 1rem;
                & .close-wrapper {
                    transform: rotate(180deg);
                    color: GetPrimaryColor('white-regular');
                }
                .heading {
                    font: 1.333rem/1.5rem 'Lexend-Bold';
                }
            }

        }
        .side-nav-items-wrapper {
            // background-color: GetOtherColor('grey-regular');
            height: 100%;
            .side-nav-items {
                position: absolute;
                width: 100%;
                z-index: 2;
                display: flex;
                flex-direction: column;
                list-style: none;
                margin: 0;
                padding: 0;
                height: inherit;
                .side-nav-item {
                    @extend %side-nav-item;
                    padding: 2rem 1rem;
                    &:nth-child(2)::after {
                        position: absolute;
                        height: 1px;
                        content: '';
                        left: 0.5rem;
                        right: 0.5rem;
                        bottom: 0;
                        background: GetSecondaryColor('grey-light');
                    }
                    &.needs-separator::after {
                        height: 0px;
                    }
                    &.active {
                        @include transitions-slow;
                        background-color: GetPrimaryColor('white-regular');
                        border-left: 8.5px solid GetSecondaryColor('blue-dark');
                        font-weight: 600;
                        border-bottom: none;
                        padding-left: 9.5px;
                        &::after {
                            height: 0px;
                        }
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}