.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(GetPrimaryColor('white-regular'), 0.85);
    z-index: 99999;
    .spinner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .loading-flex {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            width: calc(100% - 20px);
            @include md {
                width: 60%;
            }
            @include lg {
                width: 40%;
            }
            .spinner-text {
                color: GetPrimaryColor("blue-dark");
                font-weight: 700;
                padding-left: 15px;
                h4 {
                    margin: 0;
                }
                &.offset-50 {
                    padding-left: 65px;
                }
            }
        }
    }
    
    // & > div {
    //     &.spinner {
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate(-50%, -50%);
    //         & > img {
    //             height: 100px;
    //             width: 100px;
    //         }
    //         & > div {
    //             &.spinner-text {
    //                 color: GetPrimaryColor("blue-regular");
    //             }
    //         }
    //     }
    // }
}
