// --------------------------------------------------
// ce containers
// --------------------------------------------------

.#{$ce-prefix}container,
.#{$ce-prefix}container-full,
.#{$ce-prefix}container-fluid {
    @include box-sizing(border-box);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: ($ce-grid-spacing * 2);
    padding-left: ($ce-grid-spacing * 2);

    .#{$ce-prefix}grid {
        margin-right: -$ce-grid-spacing;
        margin-left: -$ce-grid-spacing;
    }
}

.#{$ce-prefix}container {

    @media (min-width: $ce-sm) {
        max-width: $ce-sm;
    }

    @media (min-width: $ce-md) {
        max-width: $ce-md;
    }

    @media (min-width: $ce-lg) {
        max-width: $ce-lg;
    }

    @media (min-width: $ce-xl) {
        max-width: $ce-xl;
    }
}

// --------------------------------------------------
// ce grid
// --------------------------------------------------

.#{$ce-prefix}grid {
    @include box-sizing(border-box);
    display: block;
    @include display-flex();
    @include flex-wrap();
    padding: 0;
    margin: 0 auto;
    position: relative;
    @include setup-whitespace();

    &::before,
    &::after {
        @include box-sizing(border-box);
        @include reset-whitespace();
    }

    // handle when lists are used as a grid
    list-style-type: none;
}

// --------------------------------------------------
// ce col defaults
// --------------------------------------------------

[class*="#{$ce-prefix}col-"] {
    @include box-sizing(border-box);
    @include reset-whitespace();
    position: relative;
    width: 100%;
    vertical-align: top;
    padding: $ce-grid-spacing;

    &::before,
    &::after {
        @include box-sizing(border-box);
        @include reset-whitespace();
    }

    // handle grids nested in columns
    .#{$ce-prefix}grid {
        @include flex(1, 1, auto);
        margin: -$ce-grid-spacing;
    }

    @if $legacy-support == true {
        display: inline-block;
        *display: inline;
        zoom: 1;
    }
}

// --------------------------------------------------
// ce grid generation
// --------------------------------------------------

@include make-ce-grid(col-);

@media (min-width: $ce-sm) {
    @include make-ce-grid(col-sm-);
}

@media (min-width: $ce-md) {
    @include make-ce-grid(col-md-);
}

@media (min-width: $ce-lg) {
    @include make-ce-grid(col-lg-);
}

@media (min-width: $ce-xl) {
    @include make-ce-grid(col-xl-);
}

// --------------------------------------------------
// ce col-auto
// --------------------------------------------------

.#{$ce-prefix}col-auto {
    @include setup-auto-cols();
}

@media (min-width: $ce-sm) {
    .#{$ce-prefix}col-sm-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $ce-md) {
    .#{$ce-prefix}col-md-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $ce-lg) {
    .#{$ce-prefix}col-lg-auto {
        @include setup-auto-cols();
    }
}

@media (min-width: $ce-xl) {
    .#{$ce-prefix}col-xl-auto {
        @include setup-auto-cols();
    }
}

// --------------------------------------------------
// ce order helpers generation
// --------------------------------------------------

@include make-order-helpers();


@media only screen and (min-width: $ce-sm) {
    @include loop-order-helpers($ce-columns, '-sm');
}

@media only screen and (min-width: $ce-md) {
    @include loop-order-helpers($ce-columns, '-md');
}

@media only screen and (min-width: $ce-lg) {
    @include loop-order-helpers($ce-columns, '-lg');
}

@media only screen and (min-width: $ce-xl) {
    @include loop-order-helpers($ce-columns, '-xl');
}

// --------------------------------------------------
// ce offset helpers generation
// --------------------------------------------------

@include make-offset-helpers();


@media only screen and (min-width: $ce-sm) {
    @include loop-offset-helpers($ce-columns - 1, '-sm');
}

@media only screen and (min-width: $ce-md) {
    @include loop-offset-helpers($ce-columns - 1, '-md');
}

@media only screen and (min-width: $ce-lg) {
    @include loop-offset-helpers($ce-columns - 1, '-lg');
}

@media only screen and (min-width: $ce-xl) {
    @include loop-offset-helpers($ce-columns - 1, '-xl');
}
