.job-detail-car-icon {
  color: GetPrimaryColor('blue-regular');
  height: 4rem;
  width: 4rem;
  top: 0.4rem;
  .icon {
      font-size: 4rem;
  }
}

.job-details-next-btn {
  .btn {
    width: auto;
  }
}
.job-details-request-data-view {
  margin-top: 2rem;
}
.job-requests-wrapper {
  @include job-requests-wrapper(normal);
}
.spanStyle{
  color: #ec5e5e !important;
  font-size: 27px !important;
  vertical-align: middle !important; 
}


