.details-wrapper {
    position: fixed;
    width: 100%;
    // z-index: 2; // This produces an overlay issue when you have a component rendered inside the details box
    .details-nav-button {
        background: GetPrimaryColor('white-regular');
        // padding: 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        // border-bottom: 1px solid GetPrimaryColor('grey-light');
        // margin-bottom: 1rem;
        @include transitions-slow;
        & .close-wrapper {
            position: relative;
            color: GetPrimaryColor('blue-regular');
            height: 4.444rem;
            width: 4.444rem;
            // top: 0.75rem; //calc((100% - 2rem) / 2);
            right: 15px;
            .icon {
                font-size: 4.444rem;
            }
        }
        &.show {
            // background-color: GetPrimaryColor('blue-regular');
            // color: GetPrimaryColor('white-regular');
            // padding: 2rem 1rem;
            & .close-wrapper {
                transform: rotate(180deg);
                // color: GetPrimaryColor('white-regular');
            }
            // .heading {
                // font: 1.333rem/1.5rem 'Lexend-Bold';
            // }
        }

    }
    .details-nav-items-wrapper {
        // background-color: GetOtherColor('grey-regular');
        height: 100%;
        // margin-bottom: 1rem;
        .details-nav-items {
            position: relative;
            // background-color: GetOtherColor('grey-regular');
            width: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;
            height: inherit;
            .details-nav-item {
                @extend %side-nav-item;
                padding: 2rem 1rem;
                &:not(:nth-child(1))::after {
                    position: absolute;
                    height: 1px;
                    content: '';
                    left: 0.5rem;
                    right: 0.5rem;
                    bottom: 0;
                    background: GetSecondaryColor('grey-light');
                }
                &:last-child {
                    padding: 1rem 1.5rem;
                    &::after {
                        position: unset;
                    }
                }
                &.active {
                    @include transitions-slow;
                    background-color: GetPrimaryColor('white-regular');
                    border-left: 8.5px solid GetSecondaryColor('blue-dark');
                    font-weight: 600;
                    border-bottom: none;
                    padding-left: 9.5px;
                    &::after {
                        position: unset;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.service-box-card{
  background-color: white;
  position: relative;
  margin-top: -4.5rem;
  box-shadow: 1px 1px 5px #e8e8e8;
  height:100%;
  margin-top: 0px;
  border: 1px solid #979797;
  border-top: 4px solid GetPrimaryColor('blue-regular');
}

.details-box-header {
  margin-bottom: 0px;
  margin-top:1rem;
  align-self: baseline;
  margin-right: 1rem;
  display: flex;
  align-items: baseline;
}

.details-box-header-wrapper {
  display: inline;
  @include sm {
    display: inline-flex;
  }
}

.details-link-btn {
  color: GetPrimaryColor("blue-dark") !important;
  font-size: 1.1111rem;
  text-decoration: underline !important;
  button {
    min-width: 0rem;
  }
}

.details-box-link {
  display: inline-flex;
}

.details-box-caret-col {
  text-align: right;
  position: inherit;
}

.details-content-area {
  overflow: hidden;
}

.facility-id {
  .k-grid tr td:first-child {
    font-weight: 600;
  }
}
.iconStyle{
  color: #ec5e5e;
    font-size: 27px;
    vertical-align: middle;
}
