@use "sass:math";
@use "sass:list";

@import "variables/breakpoints";
@import "variables/colors";
@import "variables/typography";

@mixin typography($theme) {
  html,
  body {
    -webkit-font-smoothing: antialiased;
    font-family: "OpenSans", "Arial", "sans-serif" !important;
    font-size: $base-font-size;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body,
  small,
  p {
    margin: 0;
  }

  a {
    font-family: "OpenSans-Bold", Arial, sans-serif;
    text-decoration: underline;
    color: var(--primaryColor);

    &:hover {
      color: var(--primaryDarkColor);
      cursor: pointer;
    }

    &:active {
      color: var(--primaryDarkColor);
    }

    &:focus-visible {
      outline: 4px solid $ce-white;
      outline-offset: 0px !important;
      border: 4px solid var(--primaryColor);
      padding: 2px;
    }

    &.disabled {
      color: $ce-medium-gray;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.body2 {
      font-size: 0.8rem;
    }

    &.smallcopy {
      font-size: 0.64rem;
    }

    &.light {
      color: $ce-white;
      &:hover {
        color: $ce-blue-gray;
        cursor: pointer;
      }

      &:active {
        color: $ce-blue-gray;
      }
      @if ($theme == "or") {
        &:hover {
          color: $or-yellow;
          cursor: pointer;
        }

        &:active {
          color: $or-yellow;
        }
      }

      &.disabled {
        color: $ce-white;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

@include typography($theme-default);

@mixin sm {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

// To calculate margins:
// Bootstrap defaults: margin-top: 0; margin-bottom: 0.5rem;
// Alternatively: 1 / line-height

.hero {
  @include sm {
    font: bolder 1.685rem/2rem "Lexend-ExtraBold", sans-serif;
  }

  @include md {
    font: bolder 1.685rem/2rem "Lexend-ExtraBold", sans-serif;
  }

  @include lg {
    font: bolder 2.888rem/3.556rem "Lexend-ExtraBold", sans-serif;
  }

  @include xl {
    font: bolder 3.777rem/4.444rem "Lexend-ExtraBold", sans-serif;
  }
}

h1 {
  @include sm {
    font: bolder 1.518rem/1.889rem "Lexend-ExtraBold", sans-serif;
  }

  @include md {
    font: bolder 1.518rem/1.889rem "Lexend-ExtraBold", sans-serif;
  }

  @include lg {
    font: bolder 2.222rem/2.889rem "Lexend-ExtraBold", sans-serif;
  }

  @include xl {
    font: bolder 2.888rem/3.556rem "Lexend-ExtraBold", sans-serif;
  }
}

h2 {
  @include sm {
    font: bolder 1.333rem/1.667rem "Lexend-ExtraBold", sans-serif;
  }

  @include md {
    font: bolder 1.333rem/1.667rem "Lexend-ExtraBold", sans-serif;
  }

  @include lg {
    font: bolder 1.777rem/2.444rem "Lexend-ExtraBold", sans-serif;
  }

  @include xl {
    font: bolder 2.222rem/2.889rem "Lexend-ExtraBold", sans-serif;
  }
}

h3 {
  @include sm {
    font: bolder 1.222rem/1.667rem "Lexend-ExtraBold", sans-serif;
  }

  @include md {
    font: bolder 1.222rem/1.667rem "Lexend-ExtraBold", sans-serif;
  }

  @include lg {
    font: bolder 1.555rem/2.222rem "Lexend-ExtraBold", sans-serif;
  }

  @include xl {
    font: bolder 1.777rem/2.444rem "Lexend-ExtraBold", sans-serif;
  }
}

h4 {
  @include sm {
    font: bolder 1.111rem/1.667rem "Lexend-ExtraBold", sans-serif;
    &.normal {
      @include sm {
        font: 1.111rem/1.667rem "Lexend", sans-serif;
      }
    }
  }

  @include md {
    font: bolder 1.111rem/1.667rem "Lexend-ExtraBold", sans-serif;
    &.normal {
      @include md {
        font: 1.111rem/1.667rem "Lexend", sans-serif;
      }
    }
  }

  @include lg {
    font: bolder 1.333rem/2rem "Lexend-ExtraBold", sans-serif;
    &.normal {
      @include lg {
        font: 1.333rem/2rem "Lexend", sans-serif;
      }
    }
  }

  @include xl {
    font: bolder 1.333rem/2rem "Lexend-ExtraBold", sans-serif;
    &.normal {
      @include xl {
        font: 1.333rem/2rem "Lexend", sans-serif;
      }
    }
  }
}

p {
  @include sm {
    font: 1rem/1.5rem "OpenSans", sans-serif;
    &.bold {
      @include sm {
        font: bolder 1rem/1.5rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      @include sm {
        font: 0.8rem/1.167rem "OpenSans", sans-serif;
      }
    }
  }

  @include md {
    font: 1rem/1.5rem "OpenSans", sans-serif;
    &.bold {
      @include md {
        font: bolder 1rem/1.5rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      @include md {
        font: 0.8rem/1.167rem "OpenSans", sans-serif;
      }
    }
  }

  @include lg {
    font: 1rem/1.5rem "OpenSans", sans-serif;
    &.bold {
      @include lg {
        font: bolder 1rem/1.5rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      @include lg {
        font: 0.8rem/1.167rem "OpenSans", sans-serif;
      }
    }
  }

  @include xl {
    font: 1rem/1.556rem "OpenSans", sans-serif;
    &.bold {
      @include xl {
        font: bolder 1rem/1.556rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      font: 0.8rem/1.167rem "OpenSans", sans-serif;
    }
  }
}

ul.list-bulleted > li,
ol.list-numbered > li {
  @include sm {
    font: 1rem/2rem "OpenSans", sans-serif;
    &.bold {
      @include sm {
        font: bolder 1rem/2rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      @include sm {
        font: 0.8rem/1.167rem "OpenSans", sans-serif;
      }
    }
  }

  @include md {
    font: 1rem/2rem "OpenSans", sans-serif;
    &.bold {
      @include md {
        font: bolder 1rem/2rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      @include md {
        font: 0.8rem/1.167rem "OpenSans", sans-serif;
      }
    }
  }

  @include lg {
    font: 1rem/2rem "OpenSans", sans-serif;
    &.bold {
      @include lg {
        font: bolder 1rem/2rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      @include lg {
        font: 0.8rem/1.167rem "OpenSans", sans-serif;
      }
    }
  }

  @include xl {
    font: 1rem/2rem "OpenSans", sans-serif;
    &.bold {
      @include xl {
        font: bolder 1rem/2rem "OpenSans", sans-serif;
      }
    }
    &.body2 {
      font: 0.8rem/1.167rem "OpenSans", sans-serif;
    }
  }
}

small {
  @include sm {
    font: 0.64rem/1rem "OpenSans", sans-serif !important;
  }

  @include md {
    font: 0.64rem/1rem "OpenSans", sans-serif !important;
  }

  @include lg {
    font: 0.64rem/1rem "OpenSans", sans-serif !important;
  }

  @include xl {
    font: 0.64rem/1rem "OpenSans", sans-serif !important;
  }
}

// TODO: 'Main navigation' typography (seems to be 'desktop' only)

@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf woff) {
  $src: null;

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $ext)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// =============================================================================
// Fonts
// =============================================================================

$lexend-light: "./font-data/Lexend-Light";
$lexend-regular: "./font-data/Lexend-Regular";
$lexend-demi: "./font-data/Lexend-SemiBold";
$lexend-semibold: "./font-data/Lexend-SemiBold";
$lexend-ExtraBold: "./font-data/Lexend-ExtraBold";
$openSans-light: "./font-data/OpenSans-Light";
$openSans-regular: "./font-data/OpenSans-Regular";
$openSans-semibold: "./font-data/OpenSans-Semibold";
$openSans-bold: "./font-data/OpenSans-Bold";

$coned-icons: "./font-data/coned-icons";

@include font-face("Lexend-Light", $lexend-light, 200, lighter, ttf);
@include font-face("Lexend", $lexend-regular, 400, normal, ttf);
@include font-face("Lexend-Semibold", $lexend-demi, 400, normal, ttf);
@include font-face("Lexend-Semibold", $lexend-semibold, 600, bold, ttf);
@include font-face("Lexend-ExtraBold", $lexend-ExtraBold, 800, bolder, ttf);
@include font-face("OpenSans-Light",$openSans-light,  200, lighter, ttf woff);
@include font-face(
  "OpenSans",
  $openSans-regular,
  400,
  normal,
  ttf woff
);
@include font-face(
  "OpenSans-Semibold",
  $openSans-semibold,
  600,
  bold,
  ttf woff
);
@include font-face(
  "OpenSans-Bold",
  $openSans-bold,
  800,
  bolder,
  ttf woff
);

@include font-face("coned-icons", $coned-icons, 500, normal, woff);

// =======

// ===
// Lists
// ===

ul.list-bulleted,
ol.list-numbered,
ol.list-lettered {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 1rem;

  line-height: 2;
}

ul.list-bulleted > li::before,
ol.list-numbered > li::before,
ol.list-lettered > li::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 0.5rem;
}

ul.list-bulleted {
  list-style-type: disc;
}

ol.list-numbered {
  list-style-type: decimal;
}

ol.list-lettered {
  list-style-type: lower-alpha;
}

// Inner Lists should have an additional inset
ul[class^="list-"] > [class^="list-"],
ol[class^="list-"] > [class^="list-"] {
  padding-left: 1.667rem;
}

// ===
// Divider
// ===

hr {
  border-top: 1px solid $ce-medium-gray;
}

// ===
// Display utils
// ===

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

// ===
// Text utils
// ===

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

// Simple tooltip styling
.simple-tooltip {
  position: absolute;
  top: 100%;
  z-index: 100;
  width: auto;
  padding: 0px $eight;
  height: fit-content;
  text-align: center;
  background-color: var(--secondaryDarkColor);
  color: $ce-white;
  border-radius: $four;
  font-size: $font-small-size;
  font-family: "OpenSans-Semibold";
}
