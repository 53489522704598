.esri-view {
    height: 535px;
    width: 100%;
    // border: 1px solid rgba(10, 10, 10, 0.25);
}

:host::ng-deep .esri-view-height-less-than-medium .esri-popup__main-container {
    max-height: 500px !important;
}

:host::ng-deep .infobox-link {
    color: #0078cf !important;
    text-transform: none;
}

:host::ng-deep .esri-popup-truck-icon {
    font-size: 1.3rem;
    color: #069bd7;
    margin-top: -4px;
}

:host::ng-deep .esri-popup-circle-icon {
    color: rgb(22, 184, 22);
    font-size: 1.3rem;
    margin-top: -4px;
}

.map-wrapper {
    position: relative;
    height: 535px;
    // Override parent width on mobile
    width: 100vw;
    margin-left: -0.5rem;
    margin-top: -3rem;
    @include sm {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
    }
    i[class^='map-btn'] {
        height: 55px;
        width: 55px;
        background: #ffffff;
        color: #0078cf;
        right: 1%;
        position: absolute;
        border-radius: 50%;
        padding: 15px;
        outline: 1px solid #0078cf;
        &::before {
            font-size: 55px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &:hover {
            cursor: pointer;
        }
        &.active {
            background: #0078cf;
            color: #ffffff;
            outline-color: #ffffff;
        }
    }
    i[class^='map-btn-1'] {
        text-align: center;
        bottom: 17.5%;
    }
    i[class^='map-btn-2'] {
        bottom: 5%;
    }
    .esri-component.esri-basemap-toggle.esri-widget {
        .esri-basemap-toggle__image--secondary {
            margin-top: 0;
            margin-left: 0;
        }
    }
    .esri-icon-visible::before {
        content: "\e997";
        color: #0078cf;
    }
    .esri-icon-right-triangle-arrow:before {
        content: "\e940";
        transform: rotate(90deg);
    }
    .esri-layer-list__child-toggle-icon--opened {
        transform: rotate(180deg);
    
        &.esri-icon-down-arrow::before {
            content: "\e940";
        }
    }
    .esri-icon-non-visible {
        color: #2a2a2a;
        &::before {
            content: "\e997";
        }
    }
}
.map-legend {
    display: flex;
    flex-flow: row;
    padding: 0.688rem 0rem;
    background: GetSecondaryColor("grey-background");
    // border-left: 1px solid rgba(10, 10, 10, 0.25);
    // border-bottom: 1px solid rgba(10, 10, 10, 0.25);
    width: 100%;
    flex-grow: 1;

    @media only screen and (max-width: 450px) {
        // flex-flow: column;
        padding: 0.688rem;
    }

    .legend-title {
        font-family: "OpenSans";
        font-size: 0.875rem;
        font-weight: bold;
        margin-right: 0.4rem;
    }

    .legend-option {
        display: flex;
        flex-grow: 1;
        font-size: 0.875rem;
        font-family: "OpenSans";
        padding: 0 0.5rem;

        @media only screen and (max-width: 450px) {
            padding: 0.5rem 0;
        }

        &>svg {
            margin-right: 0.4rem;
        }
    }
    .row {
        width: 140%;
        @include lg {
            width: 70%
        }
    }
}

:host::ng-deep .loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2a2a2a;
    background-color: rgba(#ffffff, 0.6);
}

/* :host::ng-deep .esri-popup__footer {
  justify-content: flex-start;
}
:host::ng-deep .esri-popup__inline-actions-container {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

:host::ng-deep .esri-popup__navigation {
  margin: 0 auto;
  justify-content: flex-end;
} */

:host::ng-deep .esri-feature__field-data>a {
    color: #0078cf !important;
    text-transform: none;
}

.esri-popup__header {
    display: none;
}

.esri-popup--shadow {
    box-shadow: 0 !important;
}

.esri-popup__main-container {
    outline: 1px solid #008FD1;
    width: 150px !important;
}

.esri-popup__content {
    height: 120px !important;
}

// Remove 'zoom to' only
.esri-popup__button.esri-popup__action {
    display: none;
}

// Applying rounded corners
.esri-popup__main-container, .esri-popup {
    border-radius: 8px;
}

.esri-attribution__sources, .esri-attribution__powered-by{
    display: none;
}