/* Global Variables */
@import "variables/colors/common";
@import "variables/colors/ce";
@import "variables/colors/or";
@import "variables/colors";
@import "variables/spacing-sizing";
@import "variables/breakpoints";
@import "variables/typography";
@import "variables/vendor-prefixes";

/* Global Styles */

@import "reset";
@import "typography";
@import "fonts";
@import "icons";
@import "grid";
@import "margin";
@import "padding";
@import "theme";

// @import 'mixins';
.k-pdf-export .k-grid .hide-in-pdf {
    display: none;
}
@mixin ce-theme() {
    --brandColor: #{$ce-brand-blue};
    --bgColor: #{$ce-energy-blue};
    --primaryColor: #{$ce-energy-blue};
    --primaryDarkColor: #{$ce-dark-blue};
    --secondaryColor: #{$ce-energy-blue};
    --secondaryDarkColor: #{$ce-dark-blue};
    --textColor: #{$ce-dark-gray};
    --bgTextColor: #{$ce-white};
}
@mixin or-theme() {
    --brandColor: #{$or-brand-orange};
    --bgColor: #{$or-energy-orange};
    --primaryColor: #{$or-dark-teal};
    --primaryDarkColor: #{$or-black};
    --secondaryColor: #{$or-dark-teal};
    --secondaryDarkColor: #{$or-dark-teal};
    --textColor: #{$or-dark-gray};
    --bgTextColor: #{$or-dark-teal};
}

:root {
    @include ce-theme();
}