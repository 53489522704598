.ftux-page {
  background-color: GetPrimaryColor('white-regular');
  width: 100%;
  height: auto;
  min-height: 650px;
  margin-bottom: 2rem;
  display: flex;
  text-align: center;
  padding: 1rem 0.75rem;
  @include lg {
    min-height: 595px;
  }
  .carousel-wrapper-ftux {
    height: inherit;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .slider {
      position: relative;
      height: 100%;
      width: 100%;
      .slide {
        position: absolute;
        width: 100%;
        height: 100%;
        .slide-heading {
          color: GetPrimaryColor('blue-regular');
        } 
        .slide-img {
          margin-bottom: 1rem;
          img {
            aspect-ratio: 16 / 9;
            object-fit: cover;
            max-height: 180px;
            margin: auto;
            @include lg {
              max-height: 260px;
            }
          }
        }
        .slide-description {
          @include lg {
            width: 60%;
            margin: auto;
          }
        }
        &.hide {
          transform: translate(-125%);
          transition: transform 0.2 ease-in-out;
        }
      }
      i {
        color: GetPrimaryColor('blue-regular');
        font-size: 3.5rem;
        position: absolute;
        top: calc((100% - 3.5rem) / 4);
        background-color: GetPrimaryColor('white-regular');
        &.right {
          right: -1%;
        }
        &.left {
          left: -1%;
          transform: rotate(180deg);
        }
        &.disabled {
          color: GetPrimaryColor('grey-light');
        }
        @include md {
          font-size: 5rem;
          top: calc((100% - 5rem) / 2);
          &.right {
            right: 15%;
          }
          &.left {
            left: 15%;
          }
        }
      }
    }
    .carousel-dot-container, ce-button {
      align-self: center;
    }
    .carousel-dot-container {
      display: flex;
      align-items: center;
      margin: 0rem 0rem 1rem;
      .dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        background-color: GetPrimaryColor('white-regular');
        border-radius: 50%;
        border: GetPrimaryColor('blue-regular') 3px solid;
        display: inline-block;
        transition: opacity 0.6s ease-in-out;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      
      .dot:hover {
        background-color: GetPrimaryColor('blue-regular');
        opacity: 0.7;
      }
      
      .dot-active {
        background-color: GetPrimaryColor('blue-regular');
      }
    }
  }

}