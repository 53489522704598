.new-request-header {
    margin-bottom: 1.25rem;
  }
  
  .new-request-description {
    margin-bottom: 3.222rem;
  }
  
  .new-request-canvas-card {
    input {
      text-transform: uppercase;
    }
    @include md {
      min-height: 33.333rem;
    }
  }
  