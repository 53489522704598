.camera {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    color: GetPrimaryColor("white-regular");
    .camera-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: GetPrimaryColor('grey-dark');
        .camera-mobile {
            padding-top: 4rem;
            height: 100vh;
            // overflow-y: scroll;
            .gallery {
                position: fixed;
                left: 1rem;
                bottom: 1rem;
                width: 5rem;
                height: 5rem;
                margin: auto;
                text-align: center;
                
                img {
                    image-rendering: auto;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                .gallery-caption {
                    position: absolute;
                    background-image: -webkit-gradient(linear,left bottom,left top,from(rgba(GetPrimaryColor("grey-dark"),.85)),to(transparent));
                    background-image: linear-gradient(0deg,rgba(42,42,42,.85),transparent);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50%;
                    width: 100%;
                    font: 1rem "Lexend-Bold", sans-serif;
                    text-align: center;
                    color: GetPrimaryColor("white-regular");
                }
            }
        }
        .camera-captured-pane {
            height: inherit;
            overflow-y: auto;
            .captured-photo {
                position: relative;
                width: 100%;
                padding-top: 56.25%;
                text-align: center;
                @include transitions-slow;
                img {
                    object-fit: contain;
                    margin: auto;
                }
                .carousel-left-btn {
                    z-index: 10;
                    position: absolute;
                    background-color: GetPrimaryColor('white-regular');
                    outline: GetSecondaryColor('grey-light') 0.5px solid;
                    color: GetSecondaryColor('grey-regular');
                    height: 50px;
                    width: 50px;
                    bottom: calc((100% / 2) - 50px);
                    font-weight: 600;
                    font-size: 1.785rem;
                    display: flex;
                    justify-content: center;
                    align-content: flex-start;
                    align-items: stretch;
                    left: 1%;
                }
                .carousel-right-btn {
                    z-index: 10;
                    position: absolute;
                    background-color: GetPrimaryColor('white-regular');
                    outline: GetSecondaryColor('grey-light') 0.5px solid;
                    color: GetSecondaryColor('grey-regular');
                    height: 50px;
                    width: 50px;
                    bottom: calc((100% / 2) - 50px);
                    font-weight: 600;
                    font-size: 1.785rem;
                    display: flex;
                    justify-content: center;
                    align-content: flex-start;
                    align-items: stretch;
                    right: 1%;
                }
                @include md {
                    .carousel-left-btn,.carousel-right-btn {
                        height: 85px;
                        width: 85px;
                        bottom: calc((100% / 2) - 85px);
                        font-size: 3rem;
                    }
                }
            }
            &.mobile {
                .captured-photo {
                    padding-top: 100%;
                }
            }
            &.landscape.mobile {
                .captured-photo {
                    padding-top: 56.25%;
                }
                .comments-section {
                    height: 100vh;
                }
            }

        }
        .hide-pane-btn {
            color: GetPrimaryColor('white-regular');
            @include icon-lg;
            position: absolute;
            display: flex;
            z-index: 1;
            &.menu-options {
                top: 85vh;
                right: 1rem;
            }
        }
        .camera-shutter-pane {
            height: 20vh;
            
            .logo-take-photo {
                // text-align: center;
                text-align: -webkit-center;
            }
            .icon-social-share {
                font-size: 4rem;
            }
        }
        .camera-options-pane {
            height: 15.5vh;
        }
        .camera-shutter-pane, .camera-options-pane {
            bottom: 0;
            width: 100%;
            left: 0;
            position: fixed;
            background: rgba(GetPrimaryColor("grey-dark"),.85);
            box-shadow: 0px -1px 0.05rem 0.05rem rgba(42, 42, 42, 0.85);
            @include transitions;
            &.landscape {

            }
            &.mobile {
                height: 15vh;
                .gallery {
                    left: 0.5rem;
                    top: calc((100% - 4rem)/2);
                }
                .camera-options-buttons {
                    padding: 0rem 1rem;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                }
            }
            &.landscape.mobile {
                height: 20vh;
                .camera-options-buttons {
                    flex-direction: row;
                    height: 100%;
                    & button {
                        padding: 0.5rem 0.95rem;
                        min-height: 3rem;
                        max-height: 3rem;
                    }
                    .button-left {
                        position: initial;
                        right: unset;
                        .icon-caret-button.flip-up {
                            transform: scale(-1);
                            @include transitions-slow;
                        }
                    }
                }
                .gallery {
                    left: 1rem;
                }
            }
            &.hide {
                transform: translateY(110%);
                &.portrait {
                }
            }
            .camera-options-buttons {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                min-width: 50vw;
                @include lg {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    min-width: 33vw;
                    align-content: center;
                    height: 100%;
                    max-width: 33vw;
                    margin: auto;
                }
                .button-left {
                    position: absolute;
                    right: 1rem;
                    .icon-caret-button.flip-up {
                        transform: scale(-1);
                        @include transitions-slow;
                    }
                }
            }
            .gallery {
                position: absolute;
                left: 1rem;
                align-self: center;
                top: calc((100% - 4rem)/2);
                width: 4rem;
                height: 4rem;

                img {
                    image-rendering: auto;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                .gallery-caption {
                    position: absolute;
                    background-image: -webkit-gradient(linear,left bottom,left top,from(rgba(GetPrimaryColor("grey-dark"),.85)),to(transparent));
                    background-image: linear-gradient(0deg,rgba(42,42,42,.85),transparent);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50%;
                    width: 100%;
                    font: 1rem "Lexend-Bold", sans-serif;
                    text-align: center;
                    color: GetPrimaryColor("white-regular");
                }
            }
            .photo-status {
                position: absolute;
                left: 6rem;
                width: 60%;
                @include sm {
                    width: 30%;
                    left: 17.5rem;
                }
            }
        }
        .camera-shutter {
            width: 5rem;
            height: 5rem;
            background: GetPrimaryColor("blue-regular");
            border-radius: 50%;
            box-shadow: 0 0 20px #000;
            position: relative;
            margin: auto;
                top: calc((100% - 5rem)/2);
            &::after {
                content: "";
                border: 10px solid GetPrimaryColor("blue-regular");
                border-radius: 50%;
                width: calc(100% + 36px);
                height: calc(100% + 36px);
                left: -18px;
                top: -18px;
                box-sizing: border-box;
                position: absolute;
            }
            @include transitions;
            &.disabled {
                background: GetPrimaryColor("grey-light");
            }
        }
        .camera-loading {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: GetPrimaryColor('white-regular');
            align-items: center;
            @include transitions;
            &[hidden] {
                display: none;
                opacity: 0;
            }
            .loading-container {
                .text {
                    text-align:center;
                    &.loading-text {
                        opacity: 0;
                        animation: textAnimation 2.5s cubic-bezier(0.5,0.5,0.5,1) infinite;
                        @keyframes textAnimation {
                            50% { opacity: 1; }
                        }
                    }
                }
            }
            .gallery-error {
                position: fixed;
                left: 1rem;
                bottom: 1rem;
                width: 4rem;
                height: 4rem;
                margin: auto;
                text-align: center;
                
                img {
                    image-rendering: auto;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                .gallery-caption {
                    position: absolute;
                    background-image: -webkit-gradient(linear,left bottom,left top,from(rgba(GetPrimaryColor("grey-dark"),.85)),to(transparent));
                    background-image: linear-gradient(0deg,rgba(42,42,42,.85),transparent);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50%;
                    width: 100%;
                    font: 1rem "Lexend-Bold", sans-serif;
                    text-align: center;
                    color: GetPrimaryColor("white-regular");
                }
            }
        }
        .cancel-link {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            background: rgba(GetPrimaryColor('grey-dark'), 0.85);
            button {
                width: auto;
                @include lg {
                    width: 10%;
                }
                &:focus {
                    outline: 3px solid GetPrimaryColor('white-regular');
                }
            }
            .icon {
                @include icon-md;
            }
            .icon-caret-button {
                transform: rotate(90deg);
            }

        }
        .comments-section {
            .container.section {
                padding-top: 1rem;
            }
            background: GetPrimaryColor("white-regular");
            width: 100%;
            height: 70vh;
            display: block;
            color: GetPrimaryColor("grey-dark");
            z-index: 3;
            @include transitions-slow;

        }
     }
    .captured-video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include lg {
            height: calc(100vh - 110px);
        }
    }
}
.left-chevron {
    transform: rotate(-90deg);
}
.file-input {
    position: absolute;
    cursor: pointer;
    right: 1rem;
    font-size: 2.5rem;
    font-weight: 600;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    &:focus {
        outline: 3px solid GetPrimaryColor('white-regular');
    }
}
ce-video-upload  {
    .logo-take-photo {
        text-align: center;
    }
    .icon-social-share {
        font-size: 4rem;
    }
    .video-btn {
        top: 10%;
        position: absolute;
        width: 95%;
    }
}
.file {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 89px;
    img {
        transform: scale(0.5);
    }
    .icon {
        font-weight: 500 !important;
        @include icon-lg;
    }
}
.file-input-solo {
    position: absolute;
    right: 1rem;
    top: calc((100% - 100px)/2);
    // display: grid;
    // flex-direction: column;
    @include lg {
        top: calc((100% - 100px)/2);
    }
    input.input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    .file-input-solo-caption {
        margin-top: 0.5rem;
        @include body2-sizes;
    }
    img {
        place-self: center;
    }
}
.file-input-wrapper {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
}

.file-input-error {
    display: grid;
    flex-direction: column;
    height: 100px;
    width: 100px;
    margin: auto;
    text-align: center;
    input.input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    .file-input-solo-caption {
        margin-top: 0.5rem;
        @include body2-sizes;
    }
    img {
        place-self: center;
    }
}

.banner {
    position: absolute;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    &.banner-white {
        background-color: GetPrimaryColor('white-regular');
        color: GetPrimaryColor('grey-dark');
    }
    .icon {
        font-size: 2rem;
        color: GetSecondaryColor('green-button');
    }
}

.camera-error-msg {
  color: black;
}

.multi-photo-info {
    position: absolute;
    z-index: 1001;
    // left: calc(-286px / 10);
    right: 7rem;
    background: GetPrimaryColor('white-regular');
    min-width: 286px;
    margin: 0;
    padding: 2.625rem 2.625rem 7px;
    font: 12px 'Lexend-Semibold';
    // border: solid 1px GetOtherColor('grey-dark');
    border-radius: 0.15rem;
    box-shadow: 0 0.1rem 0.25rem 0 rgba(42,42,42, 0.2);
    .description {
        list-style: none;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        color: #2A2A2A;
        font-family: "Open Sans";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }
    .bubble-arrow {
        border-style: solid;
        position: absolute;
        border-color: white transparent transparent transparent;
        border-width: 8px 8px 0px 8px;
        right: -12px;
        top: 70px;
        transform: rotate(-90deg);
    }
}

.multi-photo-info.error {
    bottom: 70px;
}

.swap-camera {
    position: absolute;
    left: calc((100% - 17.5rem) / 2);
    top: calc((100% - 1.75rem)/2);
    display: grid;
    flex-direction: column;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}