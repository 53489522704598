// variables
$primary-background: GetPrimaryColor("blue-regular");
$plain-background: GetPrimaryColor("white-regular");
$dark-background: GetPrimaryColor("grey-dark");


.k-panelbar {
  border: none;
}
.custom-template {
  padding: 10px 5px;
}

.k-panelbar {
  @include body2-sizes;
  .k-content {
    @include small-copy-sizes;
    .content-value {
      @include body2-sizes;
      color: GetPrimaryColor('grey-dark');
    }
  }
}

// below styles Overide the default color templates set by kendo
.panel-title.k-item.k-state-disabled > span {
  background-color: $primary-background;
  color: $plain-background;
}

.panel-title > span {
  background-color: $primary-background !important;
  color: $plain-background !important;
  opacity: 1 !important;
}

.k-panelbar > .k-item > .k-link.k-state-selected,
.k-panelbar > .k-item > .k-link.k-state-selected:hover{
  background-color: $dark-background;
}

.k-panelbar>.k-item>.k-link.k-header.panel-header,
.k-link>.k-header>.k-state-selected> .k-state-focused{
  background-color: $primary-background;
}

// .k-panelbar>.k-item>.k-link, .k-panelbar>.k-panelbar-header>.k-link {
//   background-color: $primary-background;
// }


.k-panelbar>.k-item>.k-link {
  color: $dark-background;
  background-color: $plain-background;

}


.k-panelbar>.k-item>.k-link.k-selected, .k-panelbar>.k-panelbar-header>.k-link.k-selected {
  color: $dark-background;
  background-color: $plain-background;
}

.k-panelbar>.k-item>.k-link.k-selected:hover, .k-panelbar>.k-item>.k-link.k-selected.k-hover, .k-panelbar>.k-panelbar-header>.k-link.k-selected:hover, .k-panelbar>.k-panelbar-header>.k-link.k-selected.k-hover {
    background-color: $plain-background;
}

.k-panelbar>.k-item>.k-link, .k-panelbar>.k-panelbar-header>.k-link{
  color:  $dark-background;
}

#k-panelbar-0-item-default-0 > span{
  background-color: $primary-background;
  color: $plain-background;
  opacity: 1;
}


.k-text-selection ::selection,
.k-panelbar > .k-item > .k-link:hover, .k-panelbar > .k-item > .k-link.k-state-hover {
  color: initial;
  background-color: $plain-background;
}
.k-panelbar .k-item:not(:first-child) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.k-panelbar>.k-item>.k-link.k-state-selected:hover, .k-panelbar>.k-item>.k-link.k-state-selected.k-state-hover {
  background-color: $plain-background;
}

.k-state-disabled, .k-widget[disabled] {
  opacity: initial;
  filter: initial;
}

.k-panelbar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-item > .k-link.k-state-selected:hover {
  background-color: transparent;
  color: GetPrimaryColor('grey-dark');
  // border-bottom: 1px solid GetPrimaryColor('grey-dark');
}
.k-panelbar>.k-item>.k-link.k-state-focused {
  box-shadow: none;
}
.k-panelbar>.k-item+.k-item {
  border-bottom: 1px solid GetOtherColor('grey-dark');
  border-top: none;
}

.panel-bar-actions-wrapper {
  a, i {
  color:#069bd7;
  font-size: 18px
  }
a {
  text-decoration: underline;
  }
}

// Values obtained from invision
.panel-bar-content-title {
  div {
  width: 74px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  }
}

.panel-bar-content {
  div {
    width: 71px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    overflow-wrap: anywhere;
  }
}

.mobile-border-style {
  border-bottom: 1px solid #2A2A2A; 
  margin: 0 0 .5rem 0;
}