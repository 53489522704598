// =============================================================================
// Lock Image in Portrait
// =============================================================================

@mixin lock-landscape {
	@media screen and (orientation: landscape) {
		// DO NOTHING
	}
	@media screen and (orientation: portrait) {
		@content;
	}
}
// =============================================================================
// Lock Image in Landscape
// =============================================================================

@mixin lock-portrait {

}
// =============================================================================
// Checkmark animation
// =============================================================================

@keyframes draw-checkbox {
  0% {
    stroke-dashoffset: 33;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

// =============================================================================
// Hover state
// =============================================================================
@mixin hover-state {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}
// =============================================================================
// Transition state speed
// =============================================================================
@mixin transitions {
	transition-property: all;
	transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}
@mixin transitions-slow {
	transition-property: all;
	transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
// =============================================================================
// Small Devices Height Breakpoint
// =============================================================================
@mixin sm-height {
  @media (min-height: #{$screen-sm-min}) {
      @content;
  }
}

// =============================================================================
// Medium Devices Height Breakpoint
// =============================================================================
@mixin md-height {
  @media (min-height: #{$screen-md-min}) {
      @content;
  }
}

// =============================================================================
// Large Devices Height Breakpoint
// =============================================================================
@mixin lg-height {
  @media (min-height: #{$screen-lg-min}) {
      @content;
  }
}

// =============================================================================
// XL Devices Height Breakpoint
// =============================================================================
@mixin xl-height {
  @media (min-height: #{$screen-xl-min}) {
      @content;
  }
}
// =============================================================================
// XXL Devices Height Breakpoint
// =============================================================================
@mixin xxl-height {
  @media (min-height: #{$screen-xxl-min}) {
      @content;
  }
}

// =============================================================================
// Small Devices Width Breakpoint
// =============================================================================
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

// =============================================================================
// Medium Devices Width Breakpoint
// =============================================================================
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }

// =============================================================================
// Large Devices Width Breakpoint
// =============================================================================
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }

// =============================================================================
// XL Devices Width Breakpoint
// =============================================================================
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }
 // =============================================================================
// XXL Devices Width Breakpoint
// =============================================================================
@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
 }
// =============================================================================
// Establish base font
// =============================================================================
 @mixin base-font {
	font-size: $base-font-size;
}
// =============================================================================
// Establish typography rules with vertical rythm
// =============================================================================
@mixin hero-sizes {
	font: 1.685rem/2rem "Lexend-Bold",sans-serif;

		@include md {
				font: 2.888rem/3.555rem "Lexend-Bold",sans-serif;
		}
		@include lg {
				font: 3.777rem/4.444rem "Lexend-Bold",sans-serif;
		}
}
@mixin hero-vertical-rythm {
	margin-bottom: 2rem;
	@include md {
		margin-bottom: 3.555rem;
	}
	@include lg {
		margin-bottom: 4.444rem;
	}
}
@mixin h1-sizes {
	font: 1.518rem/1.888rem "Lexend-Bold",sans-serif;
	@include md {
			font: 2.222rem/2.888rem "Lexend-Bold",sans-serif;
	}
	@include lg {
			font: 2.888rem/3.556rem "Lexend-Bold",sans-serif;
	}
}
@mixin h1-vertical-rythm {
	margin-bottom: 1.888rem;
	@include md {
		margin-bottom: 2.888rem;
	}
	@include lg {
		margin-bottom: 3.556rem;
	}
}
@mixin h2-sizes {
	font: 1.333rem/1.25rem "Lexend-Bold",sans-serif;
	@include md {
			font: 1.777rem/2.444rem "Lexend-Bold",sans-serif;
	}
	@include lg {
			font: 2.222rem/2.888rem "Lexend-Bold",sans-serif;
	}
}
@mixin h2-vertical-rythm {
	margin-bottom: 1.25rem;
	@include md {
		margin-bottom: 2.444rem;
	}
	@include lg {
		margin-bottom: 2.888rem;
	}
}
@mixin h3-sizes {
	font: 1.222rem/1.685rem "Lexend-Bold",sans-serif;
	@include md {
			font: 1.555rem/2.222rem "Lexend-Bold",sans-serif;
	}
	@include lg {
			font: 1.777rem/2.444rem "Lexend-Bold",sans-serif;
	}
}
@mixin h3-vertical-rythm {
	margin-bottom: 1.685rem;
	@include md {
		margin-bottom: 2.222rem;
	}
	@include lg {
		margin-bottom: 2.444rem;
	}
}
@mixin h4-sizes {
	font: 1.111rem/1.685rem "Lexend-Bold",sans-serif;
	@include md {
			font: 1.333rem/2rem "Lexend-Bold",sans-serif;
	}
	@include lg {
			font: 1.333rem/2rem "Lexend-Bold",sans-serif;
	}
}
@mixin h4-normal-sizes {
	font: 1.111rem/1.685rem "Lexend-Light",sans-serif;
	@include md {
			font: 1.333rem/2rem "Lexend-Light",sans-serif;
	}
	@include lg {
			font: 1.333rem/2rem "Lexend-Light",sans-serif;
	}
}
@mixin h4-vertical-rythm {
	margin-bottom: 1.685rem;
	@include md {
		margin-bottom: 2rem;
	}
}
@mixin body-sizes {
	font: 1rem/1.55555rem "OpenSans",sans-serif;
	// @include lg {
	// 	font: 1rem/1.555rem "OpenSans",sans-serif;
	// }
}
@mixin body-vertical-rythm {
	margin-bottom: 0.77778rem;
	@include lg {
		margin-bottom: 1.555rem;
	}
}
@mixin body2-sizes {
	font: 0.77778rem/1.16667rem "OpenSans",sans-serif;
}
@mixin body2-vertical-rythm {
	margin-bottom: 1.166667rem;
}
@mixin small-copy-sizes {
	font: 0.64rem/1rem "OpenSans",sans-serif;
	@include lg {
		font: 0.67/1rem "OpenSans",sans-serif;
	}
}
@mixin small-copy-vertical-rythm {
	margin-bottom: 1rem;
}
@mixin input-field-font {
	font: 1rem/1.555rem "OpenSans-Semibold",sans-serif;
}
@mixin input-field-label {
	font: 1rem/1.555rem "OpenSans-Semibold",sans-serif;
}
@mixin primary-header-sizes {
	min-height: $primary-toolbar-height-sm;
	@include md {
		min-height: $primary-toolbar-height-lg;
	}
}
@mixin secondary-header-sizes {
	@include xl {
		min-height: $secondary-toolbar-height;
	}
}
@mixin secondary-footer-sizes {
	min-height: $secondary-toolbar-height;
}
@mixin top-spacing-from-navbar {
	padding-top: 3rem;
    @include md {
        padding-top: 1rem;
	}
	@include xl {
		padding-top: 2rem;
	}
}
@mixin icon-sm {
	font-size: 1.25rem;
	font-weight: 600;
	@include lg {
		font-size: 1.5rem;
	}
	@include xl {
		font-size: 1.75rem;
	}
}
@mixin icon-sm-dim {
	height: 1.25rem;
	width: 1.25rem;
	@include lg {
		height: 1.5rem;
		width: 1.5rem;
	}
	@include xl {
		height: 1.75rem;
		width: 1.75rem;
	}
}
@mixin icon-md {
	font-size: 1.5rem;
	font-weight: 600;
	@include lg {
		font-size: 1.75rem;
	}
	@include xl {
		font-size: 1.875rem;
	}
}
@mixin icon-md-dim {
	height: 1.5rem;
	width: 1.5rem;
	@include lg {
		height: 1.75rem;
		width: 1.75rem;
	}
	@include xl {
		height: 1.875rem;
		width: 1.875rem;
	}
}
@mixin icon-lg {
	font-size: 2.5rem;
	font-weight: 600;
	@include lg {
		font-size: 3rem;
	}
	@include xl {
		font-size: 4.5rem;
	}
}
@mixin icon-lg-dim {
	height: 2.5rem;
	width: 2.5rem;
	@include lg {
		height: 3rem;
		width: 3rem;
	}
	@include xl {
		height: 4.5rem;
		width: 4.5rem;
	}
}
@mixin icon-navbar {
	font-size: 2.75rem;
	@include xl {
		font-size: 1.5rem;
	}
}
@mixin job-requests-wrapper ($fontweight) {
  display: flex;
  font-family: "OpenSans";
  flex-direction: column;
  .k-grid {
    @include body-sizes;
    td {
      @include body-sizes;
    }
    table tbody tr {
      @include transitions-slow;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .k-grid-header {
    .k-header {
      @include body-sizes;
      font-weight: $fontweight;
    }
  }
}
