.support-links {
    list-style: none;
    padding: 0;
    // TODO: Changes ccl default font, make span to be consistent with rest of app
    span {
        @include body2-sizes;
        .inner-btn-link {
            padding-left: 3px !important;
        }   
    }
    .support-sublinks {
        list-style: none;
        padding-left: 10px;
        span {
            @include body2-sizes;
            .inner-btn-link {
                padding-left: 3px !important;
            }   
        }
    }
}
.support-content {
    h4 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    h4.normal {
        font-weight: 600;
    }
    p a {
        color: GetPrimaryColor('blue-regular');
        &:hover {
            color: GetPrimaryColor('blue-dark');
        }
    }
    img {
         width: 100%;
         margin-bottom: 2rem;
    }
    video {
        height: auto;
        width: 100%;
        object-fit: cover;
        margin-bottom: 2rem;
        border: 2px solid GetPrimaryColor('grey-light');
    }
    @include md {
        padding-left: 2rem;

        video {
            min-height: 450px;
        }
    }
}
.divide {
    border: 1px solid GetPrimaryColor('grey-light');
    margin-bottom: 2rem;
    .support-wrapper {
        position: relative;
        .flex-row.center {
            display: flex;
            min-height: 48px;
            max-height: 48px;
            align-items: center;
            justify-content: space-between;
            .support-header {
                margin: 0;
            }
        }
        .icon-caret-button {
            font-size: 2rem;
            color: GetPrimaryColor('blue-regular');
        }
        .support-links-wrapper {
            // position: absolute;
            background: GetPrimaryColor('white-regular');
            width: 100%;
            left: 0;
            right: 0;
        }
    }
    @include md {
        padding-top: 1rem;
        border: initial;
        border-right: 1px solid GetPrimaryColor('grey-light');
        margin-bottom: 0;
        height: auto;

    }
}
// .banner-notice {
//     padding: 0.75rem 1rem 0.75rem 0rem;
//     background: GetOtherColor("grey-dark");
//     margin-bottom: 1rem;
//     svg.icon {
//         position: absolute;
//         left: 15px;
//         height: 50px;
//         width: 50px;
//         padding-right: 15px;
//     }
//     @include md {
//         svg.icon {
//             right: 0;
//             left: initial;
//         }
//     }
//     &.banner-error {
//       background: rgba(236,94,94,0.1);
//     }
// }
svg g g#offlineAlert {
    fill: GetPrimaryColor('grey-dark') !important;
}
