$theme-ce: "ce";
$theme-or: "or";

// ! Default Theme: Set color scheme, within components and globally. Build-time.
$theme-default: $theme-ce;

@import "colors/ce";
@import "colors/or";

@import "colors/common";

// ---

// Usage in SCSS:
/*
@if ($theme == 'ce') {
  // ...
} @else {
  // ...
}
*/

// Runtime implementation:
// Allow a parent class to dictate theme; 'ce-theme' or 'or-theme'
// Example:
/*
<div class="or-theme">
  <ce-button></ce-button>
</div>
*/
// Then, in the consuming stylesheet (typically a component, with the exception being typography):
/*
:not(.or-theme) .ce-theme ce-button {
  @include button('ce');
  & ... // overrides
}

:not(.ce-theme) .or-theme ce-button {
  @include button('or');
  & ... // overrides
}

@mixin button($theme) {
  ... all button styles
}

// @include button('ce');
// @include button('or');
// or,
// @include button($theme-default);
*/
