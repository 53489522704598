html,
body {
    overscroll-behavior-x: none;
    height: 100%;
    margin: 0;
}
body {
    margin: 0;
    font-family: "OpenSans", "Helvetica Neue", sans-serif;
}
.form-group {
    margin-bottom: 2rem;
}
.pt-8 {
    padding-top: 8rem !important;
}
#main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    &.grey-background {
        background-color: GetSecondaryColor("grey-background");
    }
    &.white-background {
        background-color: white;
    }
}

.feedback-button {
    z-index: 4;
    position: fixed;
    top: 160px;
    right: -50px;
    cursor: pointer;
    transform: rotate(270deg);
    button[id^=ce] {
        margin: 0.5rem !important;
    }
    span.inner-btn.medium {
        border-radius: 0 !important;
        width: 120px !important;
        height: 50px !important;
    }
}