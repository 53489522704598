.note{
    font-size: small;
}

.hazard-notification{
    color: GetPrimaryColor("white-regular");
    background-color:GetSecondaryColor("red-regular");
    padding-top: 1rem;
    padding-left: 2.222rem;
    .icon{
        padding-top: 0.4rem;
        float: right;
    }
    h4{
        margin-bottom: 0;
        padding-left: .5rem;
    }
    p{
        padding-left: .5rem;
    }
}

.nav-box{
   // padding: 5 5px 5 5px;
    margin-top: 1.57rem;
    padding-bottom: 1.57rem;
    border: 1px solid GetSecondaryColor("grey-light");
    min-height: unset;
   // min-width: unset;
    @include md {
        min-height: 350px;
    }

    .nav-box-heading{
        margin: 3.25rem 1.25rem 2rem 1.25rem;
        text-align: center;
    }
    .nav-box-body{
        text-align: center;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        text-align: -webkit-center;
    }

    .btn-primary{
        margin-top: 40px;
    }
}
