.banner-component {
    padding: 0.75rem 1rem 0.75rem 0rem;
    margin-bottom: 1rem;
    svg.icon {
        position: absolute;
        left: 15px;
        height: 50px;
        width: 50px;
        padding-right: 15px;
    }
    @include md {
        svg.icon {
            right: 0;
            left: initial;
        }
        svg.icon-free {
            right: -16.5%;
            left: initial;
        }
    }
    & .banner-icon-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        font-size: 2rem;
        padding-right: 1rem;
        color: GetPrimaryColor("blue-regular");
    }
    &.banner-info {
        background-color: GetPrimaryColor("white-regular");
        color: GetPrimaryColor("grey-dark");
        border-bottom: 1px solid GetPrimaryColor('grey-light');
    }
    &.banner-error {
        color: GetPrimaryColor("white-regular");
        background-color:GetSecondaryColor("red-regular");
    }
    &.banner-warning {
        color: GetPrimaryColor("white-regular");
        background-color:GetSecondaryColor("yellow-regular");
    }
    &.banner-success {
        color: GetPrimaryColor("white-regular");
        background-color:GetSecondaryColor("green-button");
    }
    &.banner-error-notification {
        background-color: #fdeeee;
        svg {
            filter: invert(50%) sepia(90%) saturate(2194%) hue-rotate(326deg) brightness(110%) contrast(85%);
        }
        p {
            margin-top: .6rem;
            color: #404040;
        }
        .row {
            width: fit-content;
        }
    }
}

