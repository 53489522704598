@import "variables/spacing-sizing";

.margin-0 {
  margin: $zero;
}

.margin-1 {
  margin: $four;
}

.margin-2 {
  margin: $eight;
}

.margin-3 {
  margin: $sixteen;
}

.margin-4 {
  margin: $twenty-four;
}

.margin-5 {
  margin: $forty-eight;
}

.margin-top-bottom-0 {
  margin-top: $zero;
  margin-bottom: $zero;
}

.margin-top-bottom-1 {
  margin-top: $four;
  margin-bottom: $four;
}

.margin-top-bottom-2 {
  margin-top: $eight;
  margin-bottom: $eight;
}

.margin-top-bottom-3 {
  margin-top: $sixteen;
  margin-bottom: $sixteen;
}

.margin-top-bottom-4 {
  margin-top: $twenty-four;
  margin-bottom: $twenty-four;
}

.margin-top-bottom-5 {
  margin-top: $forty-eight;
  margin-bottom: $forty-eight;
}

.margin-right-left-0 {
  margin-right: $zero;
  margin-left: $zero;
}

.margin-right-left-1 {
  margin-right: $four;
  margin-left: $four;
}

.margin-right-left-2 {
  margin-right: $eight;
  margin-left: $eight;
}

.margin-right-left-3 {
  margin-right: $sixteen;
  margin-left: $sixteen;
}

.margin-right-left-4 {
  margin-right: $twenty-four;
  margin-left: $twenty-four;
}

.margin-right-left-5 {
  margin-right: $forty-eight;
  margin-left: $forty-eight;
}

.margin-top-0 {
  margin-top: $zero;
}

.margin-top-1 {
  margin-top: $four;
}

.margin-top-2 {
  margin-top: $eight;
}

.margin-top-3 {
  margin-top: $sixteen;
}

.margin-top-4 {
  margin-top: $twenty-four;
}

.margin-top-5 {
  margin-top: $forty-eight;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-right-0 {
  margin-right: $zero;
}

.margin-right-1 {
  margin-right: $four;
}

.margin-right-2 {
  margin-right: $eight;
}

.margin-right-3 {
  margin-right: $sixteen;
}

.margin-right-4 {
  margin-right: $twenty-four;
}

.margin-right-5 {
  margin-right: $forty-eight;
}

.margin-right-auto {
  margin-right: auto;
}

.margin-bottom-0 {
  margin-bottom: $zero;
}

.margin-bottom-1 {
  margin-bottom: $four;
}

.margin-bottom-2 {
  margin-bottom: $eight;
}

.margin-bottom-3 {
  margin-bottom: $sixteen;
}

.margin-bottom-4 {
  margin-bottom: $twenty-four;
}

.margin-bottom-5 {
  margin-bottom: $forty-eight;
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-left-0 {
  margin-left: $zero;
}

.margin-left-1 {
  margin-left: $four;
}

.margin-left-2 {
  margin-left: $eight;
}

.margin-left-3 {
  margin-left: $sixteen;
}

.margin-left-4 {
  margin-left: $twenty-four;
}

.margin-left-5 {
  margin-left: $forty-eight;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-auto {
  margin: auto;
}
