.view {
  .style-rule {
      border-bottom: 1px solid GetPrimaryColor('grey-light');
      padding-bottom: 3em;
      margin-bottom: 3em;
  }
  .style-rule-header {
      text-align: center;
  }
  &.contain-to-viewport {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow-y: hidden;
      top: 0;
      left: 0;
  }
}

.style-guide-close-modal-btn-row {
  justify-content: left;
  div {
    padding-right: 0px;
  }
}

.style-guide-close-modal-btn {

  margin-bottom: 1.1111rem;
  @include md {
    margin-bottom: 0rem;
  }
}
