@import "variables/spacing-sizing";

.padding-0 {
  padding: $zero;
}

.padding-1 {
  padding: $four;
}

.padding-2 {
  padding: $eight;
}

.padding-3 {
  padding: $sixteen;
}

.padding-4 {
  padding: $twenty-four;
}

.padding-5 {
  padding: $forty-eight;
}

.padding-top-bottom-0 {
  padding-top: $zero;
  padding-bottom: $zero;
}

.padding-top-bottom-1 {
  padding-top: $four;
  padding-bottom: $four;
}

.padding-top-bottom-2 {
  padding-top: $eight;
  padding-bottom: $eight;
}

.padding-top-bottom-3 {
  padding-top: $sixteen;
  padding-bottom: $sixteen;
}

.padding-top-bottom-4 {
  padding-top: $twenty-four;
  padding-bottom: $twenty-four;
}

.padding-top-bottom-5 {
  padding-top: $forty-eight;
  padding-bottom: $forty-eight;
}

.padding-right-left-0 {
  padding-right: $zero;
  padding-left: $zero;
}

.padding-right-left-1 {
  padding-right: $four;
  padding-left: $four;
}

.padding-right-left-2 {
  padding-right: $eight;
  padding-left: $eight;
}

.padding-right-left-3 {
  padding-right: $sixteen;
  padding-left: $sixteen;
}

.padding-right-left-4 {
  padding-right: $twenty-four;
  padding-left: $twenty-four;
}

.padding-right-left-5 {
  padding-right: $forty-eight;
  padding-left: $forty-eight;
}

.padding-top-0 {
  padding-top: $zero;
}

.padding-top-1 {
  padding-top: $four;
}

.padding-top-2 {
  padding-top: $eight;
}

.padding-top-3 {
  padding-top: $sixteen;
}

.padding-top-4 {
  padding-top: $twenty-four;
}

.padding-top-5 {
  padding-top: $forty-eight;
}

.padding-top-auto {
  padding-top: auto;
}

.padding-right-0 {
  padding-right: $zero;
}

.padding-right-1 {
  padding-right: $four;
}

.padding-right-2 {
  padding-right: $eight;
}

.padding-right-3 {
  padding-right: $sixteen;
}

.padding-right-4 {
  padding-right: $twenty-four;
}

.padding-right-5 {
  padding-right: $forty-eight;
}

.padding-right-auto {
  padding-right: auto;
}

.padding-bottom-0 {
  padding-bottom: $zero;
}

.padding-bottom-1 {
  padding-bottom: $four;
}

.padding-bottom-2 {
  padding-bottom: $eight;
}

.padding-bottom-3 {
  padding-bottom: $sixteen;
}

.padding-bottom-4 {
  padding-bottom: $twenty-four;
}

.padding-bottom-5 {
  padding-bottom: $forty-eight;
}

.padding-bottom-auto {
  padding-bottom: auto;
}

.padding-left-0 {
  padding-left: $zero;
}

.padding-left-1 {
  padding-left: $four;
}

.padding-left-2 {
  padding-left: $eight;
}

.padding-left-3 {
  padding-left: $sixteen;
}

.padding-left-4 {
  padding-left: $twenty-four;
}

.padding-left-5 {
  padding-left: $forty-eight;
}

.padding-left-auto {
  padding-left: auto;
}

.padding-auto {
  padding: auto;
}
