.modal-container{
  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom:0;
  overflow-y: auto; // Element higher in height
  z-index: 10001;
  //z-index: 10002;
}
.modal-background{
  position: fixed;
  opacity: .7;
  width: 100%;
  height: 100%;
  background-color: GetSecondaryColor("grey-dark");
  z-index: 10001;
}
.modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
  background-color: GetPrimaryColor("white-regular");
  z-index: 10002;
  @include md {
    width: auto;
    height: auto;
   // max-width: 80%;
     top: 50%;
     left:50%;
     transform: translate(-50%,-50%);
   // left: 12.5%;
    max-height: 95vh;
    // overflow: hidden;
  }
  .modal_body{
    text-align: center;
    font-size: 1.2rem;
    background-color: GetPrimaryColor("white-regular");
    padding: 1rem;
  }
  .modal-heading {
    padding: 1rem 15px 0rem;
  }
}
.modal-close {
  color:GetPrimaryColor("blue-regular");
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 4rem;
  height: 4rem;
  .icon {
    font-size: 4rem;
  }
}

.modal-content-items {
  margin: 2.22222rem;
  text-align: left;
}

h4 span.icon-error.modal-heading-error {
  color: GetSecondaryColor('red-regular') !important;
}

h4 span.icon-bell.modal-heading-error  {
  color: GetSecondaryColor('red-regular') !important;
}

.modal-heading-icon {
  font-size: 80px;
  vertical-align: middle;
  display: block;
  @include md {
    display: inline;
  }
}

.modal-heading-success {
  color: GetSecondaryColor('green-button');
}

.modal-footer {
  height: 0;
}

@media (min-width: 280px) and (max-width: 450px) {
  .modal-footer {
    height: 300px;
  }
}

@media (min-width: 451px) and (max-width: 1024px) {
  .modal-footer {
    height: 200px;
  }
}