.promoted-checkbox {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  height: 2.5rem;
  position: relative;
  //Draw checkmark
  input:checked + .checkbox-label > svg {
    // Firefox doesn't render svg's that is loading with the use tag if its been set to display: none and then toggled to display: block, so you have to use tricks like this to get it to render again:
    height: 1.5rem;
    animation: draw-checkbox ease-in-out 0.3s forwards;
    left: 0.075rem;
  }
  input:checked:disabled + .checkbox-label > svg {
    // Firefox doesn't render svg's that is loading with the use tag if its been set to display: none and then toggled to display: block, so you have to use tricks like this to get it to render again:
    left: 0;
  }
  //Click color
  .checkbox-label:active {
    background-color: GetPrimaryColor('grey-light');
  }

  //Center p
  p {
    margin: auto;
    font-weight: 600;
    padding: 0.485rem 0.485rem 0rem 0rem;
  }

  //Checkbox hover when not checked condition
  input:enabled + label:hover {
    background-color: GetOtherColor('grey-regular');

    svg {
      stroke: GetOtherColor('grey-dark');
      height: 1.5rem;
      animation: draw-checkbox ease-in-out 0.3s forwards;
    }
  }

  //Checkbox hover when checked condition
  input:checked:enabled + label:hover {
    background-color: GetSecondaryColor('green-button-hover');

    svg {
      stroke: GetPrimaryColor('white-regular');
    }
  }

  // Show hover icon if not disabled
  .checkbox-label {
    color: GetPrimaryColor('blue-regular');
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 2.5rem;
    outline: 0.063rem solid GetPrimaryColor('grey-light');
    background-color: GetOtherColor('grey-light');
    transition: 0.15s all ease-out;
    //Checkmark
    svg {
      stroke: GetPrimaryColor('white-regular');
      stroke-width: 0.2rem;
      height: 0; //Firefox fix
      width: 2.5rem;
      position: absolute;
      left: 0;
      top: calc((100% - 20px) / 2);
      stroke-dasharray: 33; //Firefox fix
      @-moz-document url-prefix() {
        top:1.7rem
      }
    }
  }

  .checkbox-caption {
    padding-left: calc(2.5rem + 12px);
  }
}
/* Hide the default HTML5 checkbox input to replace it with our own checkbox */
.promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0rem;
  position: absolute;
  width: 1px;
  //Checkbox checked and enabled condition
  &:checked {
    &:enabled {
      & ~ label {
        background-color: GetSecondaryColor('green-button');
        outline: 0.25rem solid GetOtherColor('grey-regular');
      }
    }
  }
  //Checkbox disabled condition
  &:disabled {
    & ~ label {
      cursor:not-allowed;
      background-color: GetOtherColor('grey-dark');
      left: 0;
    }
  }
}
//Error
label.checkbox-label.error-checkbox-btn {
  background-color: GetOtherColor('grey-regular');
  outline: 0.25rem solid GetSecondaryColor('red-regular');
}
.checkbox-error-color {
  color: GetSecondaryColor('red-regular') !important;
}

p.checkbox-error-color {
  color: #c85050;
}

.checkbox-disabled-color {
  color: GetSecondaryColor('grey-light');
}

.checkbox-checked-color {
  color: GetSecondaryColor('green-button');
}

.checkbox-msg-align-top {
  vertical-align: top;
}

.checkbox-form-message {
  color: GetSecondaryColor('grey-dark');
}
