/* Hide the default HTML5 checkbox input to replace it with our own checkbox */
.promoted-input-radio-btn {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0rem;
  position: absolute;
  width: 1px;

  //Checkbox disabled condition
  &:disabled {
    & ~ label {
      cursor:not-allowed;
      &:after {
        background-color: GetOtherColor('grey-dark');
      }
    }
  }

  //Checkbox checked and enabled condition
  &:checked {
    &:enabled {
      & ~ label {
        &:after {
          background-color: GetSecondaryColor('green-button');
          border: 0.25rem solid GetOtherColor('grey-regular');
        }
      }
    }
  }

  //Error
  & ~ label.error-radio-btn {
    &:after {
      background-color: GetOtherColor('grey-regular');
      border: 0.25rem solid GetSecondaryColor('red-regular');
    }
  }
}


.promoted-radio-btn {
  contain: content;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

  //Center p
  p {
    margin: auto;
    font-weight: 600;
    padding: 0.5rem 0.5rem 0rem 0rem;
  }

  //Checkbox hover when not checked condition
  input:enabled + label:hover {
    &:after {
      background-color: GetOtherColor('grey-regular');
    }
  }

  //Checkbox hover when checked condition
  input:checked:enabled + label:hover {
    &:after {
      background-color: GetSecondaryColor('green-button-hover');
    }
  }

  // Show hover icon if not disabled
  .radio-btn-label {
    color: GetPrimaryColor('blue-regular');
    cursor: pointer;
    position: relative;
    &:after {
      content: "";
      height: 2.5rem;
      width: 2.5rem;
      margin-right: .66667rem;
      border-radius: 50%;
      border: 0.063rem solid GetPrimaryColor('grey-light');
      float: left;
      background-color: GetOtherColor('grey-light');
      transition: 0.15s all ease-out;
    }
  }

  //Click color
  .radio-btn-label:active::after {
    background-color: GetPrimaryColor('grey-light');
  }
}
