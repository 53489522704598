.tags-overlay {
  width: 100%;
  height: 100%;
  background: rgba(GetPrimaryColor("grey-dark"), 0.65);
  transition: all 0.3s ease-out;

  .tags-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // top:0;
    // left: 0;
    width: 100%;
    @include lg {
      height: 100%;
    }
  
    .tag {
      position: absolute;
      background: rgba(GetPrimaryColor("blue-regular"), 0.95);
      height: 1rem;
      width: 1rem;
      box-shadow: 0px 0px 1px 5px rgba(GetPrimaryColor("blue-regular"), 0.75);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .sequence {
        color: GetPrimaryColor("white-regular");
        font: 0.64rem "Lexend-Bold", sans-serif;
        vertical-align: middle;
        text-align: center;
      }
      @include sm {
        height: 2rem;
        width: 2rem;
        .sequence {
          font: 1rem "Lexend-Bold", sans-serif;
        }
      }
    }
    .small-card {
      position: relative;
      width: 75%;
      left: 0;
      padding: 1rem;
      margin: 3rem 1rem 4rem 1rem;
      z-index: 1;
      background: GetPrimaryColor("white-regular");
      color: GetPrimaryColor("grey-dark");
      box-shadow: 0 0.75rem 2rem -1rem rgba(GetPrimaryColor("grey-dark"), 0.4);
      border: 1px solid gba(GetPrimaryColor("grey-dark"), 0.4);
      transition: opacity 0.3s 0.1s ease-out;
      @include lg {
        padding: 1rem 1.5rem;
      }
      @include xl {
        padding: 0.75rem;
      }
      &.hide {
        // height: 0;
        transform: scale(0);
        opacity: 0;
      }
      .small-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .labels-close {
          color: GetPrimaryColor("blue-regular");
          @include icon-lg;
          position: absolute;
          right: 0;
          top: 0;
          font-weight: normal;
            @include xxl {
              bottom: 1rem;
              width: 50px;
            }
        }
      }
      .small-card-body {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        .badge {
          color: #069bd7;
          padding: 0.15rem 0.75rem;
          border-radius: 1.5rem;
          border: 2px solid rgba(6, 155, 215, 0.95);
          margin-right: 1rem;
          margin-bottom: 0.5rem;
          min-height: 2.5rem;
          cursor: pointer;
          font-size: 0.75rem;
          font-weight: 700;
          @include transitions;
          display: flex;
          align-items: center;
          @include lg {
            font-size: 1rem;
            font-weight: 400;
          }
          &.selected {
            color: white;
            background: rgba(GetPrimaryColor("blue-regular"), 0.95);
          }
          .icon {
            font-size: 2rem;
            color: white;
            display: none;
            &.selected {
              display: inherit;
            }
          }
        }
      }
      .close-card {
        color: GetPrimaryColor("blue-regular");
        @include icon-lg;
        position: absolute;
        right: 0;
        top: 0;
        font-weight: normal;
  
        @include lg {
        }
      }
      .close-small-card {
        width: 50px;
        @include xxl {
          position: absolute;
          bottom: 1rem;
          width: 50px;
        }
      }
    }
  }
}
.cta {
  background: rgba(GetPrimaryColor("grey-dark"), 0.65);
  padding: 1rem 1.25rem;
  color: GetPrimaryColor("white-regular");
  position: absolute;
  top: 50%;
  $l: 25%;
  $w: 50%;
  width: $w;
  left: $l;
  text-align: center;
  vertical-align: middle;
  animation: fadeOut 5s cubic-bezier(0.5, 0.5, 0.5, 1) forwards;
  @keyframes fadeOut {
    75% {
      opacity: 1;
    }
    99% {
      width: $w;
      padding: 1rem 1.25rem;
    }
    100% {
      opacity: 0;
      width: 0;
      padding: 0;
    }
  }
  @include lg {
    $w: 40%;
    $l: 30%;
  }
}
