.flush-grid-wrapper {
  overflow-y: auto !important;
  .k-widget {
    background-color: transparent !important;
  }
  td, th {
    border-bottom: 1px solid GetPrimaryColor("grey-light") !important;
  }
  .k-grid tr.k-alt {
    background-color: GetPrimaryColor('white-regular');
  }
  ::selection {
    background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
    color: GetPrimaryColor('grey-dark');
  }
  .flush-grid-cell {
    margin: 0;
    &.center-blue-check {
      text-align: center;
      color: GetPrimaryColor('blue-regular');
      font-size: 1.3rem;
    }
  }
}

//changes the mobile icons for the dashboards
kendo-panelbar-item {
  .k-icon {
    font-size: 2rem !important;
  }
  .k-i-arrow-n.k-icon {
    transform: rotateX(180deg);
  }
  .k-i-arrow-60-down::before, .k-i-arrow-s::before, .k-i-sarrow-s::before, .k-i-collapse::before, .k-i-expand-s::before {
    font-size: 2rem !important;
    font-family: "coned-icons" !important;
    content: "\E940" !important;
    color: GetPrimaryColor("blue-regular") !important;
    transform: none;
  }
  .k-i-arrow-60-up::before, .k-i-expand-n::before, .k-i-sarrow-n::before, .k-i-arrow-n::before {
    font-size: 2rem !important;
    font-family: "coned-icons" !important;
    content: "\E940" !important;
    color: GetPrimaryColor("blue-regular") !important;
    // transform: rotate(90deg) scaleX(-1);
  }

  background-color: white;
}

.flush-grid-action-header {
  color:#0581C1 !important;
  text-decoration:underline !important;
}

.flush-grid-right-arrow-icon {
  position:relative !important;
  vertical-align: middle !important;
}

.flush-grid-disabled-custom-action {
  opacity: 50%;
  pointer-events: none;
  cursor: default;
}

.flush-grid-default-action-anchor {
  color: #0581C1 !important;
  b {
    text-decoration: underline !important;
  }
  cursor: pointer;
  vertical-align: middle !important;
}

.btn-flex {
  display: flex;
}
.flush-grid-default-action-right-arrow {
  color:#0581C1 !important;
  vertical-align: middle !important;
  display: inline !important;
  text-decoration: none !important;
}

.flush-grid-checkmark-action {
  stroke: #026aad !important;
}

.k-grid-content {
  overflow-y: auto !important;
}

.k-grid-header-wrap {
  width: 102% !important;
}

.k-grid-footer-wrap {
  width: 102% !important;
}

div.k-grid-header.ng-star-inserted {
  padding: 0px !important;
}

//Test flush grid styles
.flush-grid-simple {
  // kendo-grid {
  //   line-height: 3.5rem;
  // }
  .k-widget {
    border: none !important;
  }
  .k-grid-header-wrap, .k-grid-footer-wrap {
    border: none;
  }
  .k-grid-header {
    color: GetPrimaryColor('grey-dark') !important;
    background-color: GetPrimaryColor('white-regular') !important;
    .k-header {
      color: GetPrimaryColor('grey-dark') !important;
      background-color: GetPrimaryColor('white-regular') !important;
    }
  }
  .k-grid{
    color: GetPrimaryColor('grey-dark') !important;
    font-size: 18px !important;
  }
  .k-header {
    font-family: "OpenSans" !important;
    color: black !important;
    font-size: 12px !important;
    // box-shadow: inset 0 0 0 1px lightgreen;
  }

  td, th {
    border-bottom: 1px solid GetPrimaryColor("grey-light") !important;
  }

  th {
    line-height: 2rem !important;
    padding: 0rem !important;
  }

  .k-grid table {
    border-spacing: 0px 1px !important;
  }

  td {
    padding: 1rem 0rem 1rem 0rem !important;
  }

  // //The icons within flush-grid-simple
  // .icon-trash {
  //   font-size: 2.75rem;
  //   color: GetSecondaryColor('red-regular');
  //   &:hover {
  //       cursor: pointer;
  //   }
  // }

  .search-icon {
    color: GetPrimaryColor('blue-regular');
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    &:hover {
        cursor: pointer;
    }
  }

  //checkbox location
  .promoted-checkbox {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
  }

  //highlight styles
  .highlight {
    background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
    @include transitions-slow;
    &.k-alt {
        background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
    }
    &:hover, &.k-alt:hover {
        background-color: rgba($color: GetSecondaryColor('blue-grey-light'), $alpha: 0.75);
    }

  }
  .no-highlight {
      @include transitions-slow;
      background-color: transparent !important;
  }
}

.vertical-data-style-mobile {
  @media (max-width: $screen-md-min) {
    th[aria-colindex="1"] {
      width: 35%;
    }
    td[ng-reflect-column-index="0"], td[data-kendo-grid-column-index="0"] {
      p {
        font-size: .6667rem;
        line-height: 1.1rem;
      }
      padding: .1rem 0 .1rem 0 !important;
      width: 35%;
    }
    
    th[aria-colindex="2"] {
      width: 65%;
    }
    td[ng-reflect-column-index="1"], td[data-kendo-grid-column-index="1"] {
      p {
        font-size: 1rem;
        line-height: 1.1rem;
      }
      padding: .3rem 0 .3rem 0 !important;
      width: 65%;
    }
    .k-grid td {
      border-width: 0 !important;
    }
  }
}
