@use '../utilities/variables';
@use '../utilities/mixins';
/*
    TYPOGRAPHY
    REMS and EMS
    Where base is 18px;
*/
html,body {
    @include base-font;
    -webkit-font-smoothing: antialiased;
    font-family: sans-serif;
}
h1,h2,h3,h4,h5,h6,body,small,p {
    margin: 0;
}
.hero {
    @include hero-sizes;
    @include hero-vertical-rythm;
}
h1 {
    @include h1-sizes;
    @include h1-vertical-rythm;
}
h2 {
    @include h2-sizes;
    @include h2-vertical-rythm;
}
h3 {
    @include h3-sizes;
    @include h3-vertical-rythm;
}
h4 {
    @include h4-sizes;
    @include h4-vertical-rythm;
    &.normal {
        @include h4-normal-sizes;
    }
}
p {
    @include body-sizes;
    @include body-vertical-rythm;
    &.body2 {
        @include body2-sizes;
        @include body2-vertical-rythm;
    }
}
label {
  @include body-sizes;
  &.body2 {
      @include body2-sizes;
      @include body2-vertical-rythm;
  }
}
small {
    @include small-copy-sizes;
    @include small-copy-vertical-rythm;
}
b {
    font: 1rem/1.5rem "OpenSans-Bold";
}
[class^='icon-'], [class*=' icon-'] {
    text-transform: none;
    line-height: 1;
    font-family: 'coned-icons'!important;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    speak: none;
    font-variant: normal;
}
.dash-text {
    font-size: 1.25rem;
    font-weight: 700;
}
