.start-job-submit-btn {
  padding-left: 0rem;
  padding-right: 0rem;
  margin-top: 1rem;
  @include lg {
    padding-left: 1.556rem;
    padding-right: 1.556rem;
  }
}

.start-job-router-col {
  padding-left : 0px;
  padding-right: 0px;
}

.start-job-submit-sidebar-btn-li {
  padding-left: 0px;
  padding-right: 0px;
}

.row .caption {
  color: GetSecondaryColor('grey-regular');
  font-weight: bold;
  font-size: 12px;
}

.start-job-details-box-photos {
  .large-card-wrapper {

  }
}
