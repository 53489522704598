// @use "sass:math";
@use "sass:list";

@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf woff) {
	$src: null;

	$extmods: (
		eot: "?"
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

$lexend-light: "./font-data/Lexend-Light";
$lexend-regular: "./font-data/Lexend-Regular";
$lexend-demi: "./font-data/Lexend-SemiBold";
$lexend-semibold: "./font-data/Lexend-SemiBold";
$lexend-bold: "./font-data/Lexend-Bold";
$lexend-extraBold: "./font-data/Lexend-ExtraBold";
$openSans-light: "./font-data/OpenSans-Light";
$openSans-regular: "./font-data/OpenSans-Regular";
$openSans-semibold: "./font-data/OpenSans-Semibold";
$openSans-bold: "./font-data/OpenSans-Bold";

$coned-icons: "./font-data/coned-icons";

@include font-face("Lexend-Light", $lexend-light, 200, lighter, ttf);
@include font-face("Lexend", $lexend-regular, 400, normal, ttf);
@include font-face("Lexend-Semibold", $lexend-demi, 400, normal, ttf);
@include font-face("Lexend-Semibold", $lexend-semibold, 600, bold, ttf);
@include font-face("Lexend-Bold", $lexend-bold, 800, bolder, ttf);
@include font-face("Lexend-ExtraBold", $lexend-extraBold, 800, bolder, ttf);
@include font-face("OpenSans-Light",$openSans-light,  200, lighter, ttf woff);
@include font-face("OpenSans", $openSans-regular, 400, normal, ttf woff);
@include font-face("OpenSans-Semibold", $openSans-semibold, 600, bold, ttf woff);
@include font-face("OpenSans-Bold", $openSans-bold, 800, bolder, ttf woff);

@include font-face("coned-icons", $coned-icons, 500, normal, woff);
[class^='icon-'], [class*='icon-'] {
  text-transform: none;
  line-height: 1;
  font-family: 'coned-icons' !important;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  // speak: auto;
  vertical-align: text-top; // Matches coned.com's Log In
}


.icon-xs {
  font-size: 12px;
  font-weight: 500 !important;
  vertical-align: sub;
}

.icon-sm {
  font-size: 16px;
  font-weight: 500 !important;
  vertical-align: sub;
}

.icon-md{
  font-size: 20px;
  font-weight: 500 !important;
  vertical-align: sub;
}

.icon-lg{
  font-size: 24px;
  font-weight: 500 !important;
  vertical-align: sub;
}


.icon-xl{
  font-size: 28px;
  font-weight: 500 !important;
  vertical-align: sub;
}

.icon-xxl{
  font-size: 28px;
  font-weight: 500 !important;
  vertical-align: sub;
}

