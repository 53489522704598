/*
    File that contains all CSS rules intended to override the default 'bootstrap-grid' CSS
*/
// Override breakpoints and container max-widths
$new-grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;
$new-container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px,
  xl: 1080px,
  xxl: 1400px
) !default;

.container {
    @include make-container-max-widths($new-container-max-widths, $new-grid-breakpoints);
    @include top-spacing-from-navbar;
}
.footer-secondary .container, .footer-primary .container, .header .container, .header-secondary .container, .modal .container, .card .container {
    padding-top: 0;
    @include md {
        padding-top:0;
    }
}
