.parking-rest-days-col {
  display: flex; 
  overflow: auto;
  flex-wrap: wrap;
}

.access-info-modal {
  height: fit-content;
  top: 15%;
}

.access-info-center-text {
  text-align-last: center;
}

.access-info-close-continue-modal {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.mobiledelete{
  float: right !important;
   color:#ec5e5e;
  font-size: 27px;

}
.mobilefont{
  font-size: 18px;
}

.row-eq-height {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr)); 
  grid-gap: 1em;
}

.eq-item {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr)); 
  grid-gap: 1em;
}

ce-radio-group > .flexed > * {
  flex-basis: 50% !important;
}
ul.formatted-list li {
  margin-bottom: 1rem;
  &::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: GetPrimaryColor('blue-light'); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 0rem; /* Also needed for space (tweak if needed) */
  }
}